"use strict";
import firebase from '../Firebase';

const setWordDefinition = async(word, definitionId, imgUrl, audioUrl, arrComponentIds) => {
    var db = firebase.firestore();

    db.collection("word-definition-component-map").doc().set({
        componentIds: arrComponentIds,
        definitionId: definitionId,
        word: word,
        imgUrl: imgUrl,
        audioUrl: audioUrl
    }).then(function() {
        console.log('definition created');            
    }).catch(function(error) {
       console.log(error);
    });

}

export{ setWordDefinition }