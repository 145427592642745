'use strict'

import firebase from '../Firebase';
import {getWordBaronPaymail} from "../services/word-bounty/wordBounty";

const {getBSVexchangeRate} = require("../mods/slictionary-bsv-mod")
const axios = require("axios");

var db;

const getVotes = async(authorPaymail) => {
    db = firebase.firestore();
    var voteCount = 0;

    await db.collection("votes")
    .where("author","==",authorPaymail)
    .get()
    .then((snap) => {
        voteCount = snap.size;
    });

    return voteCount;
}

const getTotalDefinitionEarnings = async(authorPaymail, definitionId) => {
    var totalEarnings = 0;
    db = firebase.firestore();
    await db.collection("votes-comp-ann-user-earnings")
        .where("owner", "==", authorPaymail)
        .where("definitionId", "==", definitionId)
        .get()
        .then(docs => {
            docs.forEach(doc => {
                totalEarnings += Number(doc.data().satAmountAgg);
            });
            return null;
        })
    
    return totalEarnings;
}

const getVoteEarnings = async(authorPaymail) => {
    db = firebase.firestore();
    var totalEarnings = 0;
    await db.collection("votes")
        .where("author","==",authorPaymail)
        .get()
        .then((snap) => {
            snap.forEach((doc) => {
                if(doc.data().amountSatoshi === undefined){
                    totalEarnings += 6250; //average satoshis for $0.01
                } else {
                    totalEarnings += doc.data().amountSatoshi;
                }
            });
        });
    
    return totalEarnings;
}

const addVote = async(id, word, author, voter) => {
    try{
        let exchangeRate = await getBSVexchangeRate();
        db = firebase.firestore();

        let tokenOwner = await getWordBaronPaymail(id); // id = componentId
        let amount = 0.01; //votes cost one cent
        let amountSatoshi = amount / exchangeRate * 100000000; 
        db.collection("votes").add({
            Id: id,
            amount: amount,
            amountSatoshi: amountSatoshi,
            exchangeRate: exchangeRate,
            author: author, //tokenOwner.data !== "" ? tokenOwner.data : author,
            datetime:  firebase.firestore.FieldValue.serverTimestamp(),
            status: "pending",
            txId: "",
            voter: voter,
            word: word,
            tokenOwner: tokenOwner.data
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
            throw error;
        });
    } catch (error){
        console.log(error);
        throw error;
    }
}

export {addVote, getVotes, getVoteEarnings, getTotalDefinitionEarnings};