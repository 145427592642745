import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import VoteCarousel from './VoteCarousel';

var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;

class TabControl extends React.Component {

    constructor(props) {
      super(props);
    }

    render() { 
        return (
            <Tabs selected="Tab 1">
            <Tab label="Definitions">
                <div style={{marginTop:"25px", background:"black"}}></div>
            <div style={{ 
                display:"flex",
                padding:'5px',
                borderRadius:'15px',
                border:'0.5px solid gray',
                width:'500px',
                background:'black',
                color:'white'}}>1. a sweet, crystalline substance, C12H22O11, obtained chiefly from the juice of the sugarcane and the sugar beet, and present in sorghum, maple sap, etc.: used extensively as an ingredient and flavoring of certain foods and as a fermenting agent in the manufacture of certain alcoholic beverages; sucrose.Compare beet sugar, cane sugar.
              </div>
              <div style={{display:"flex"}}>author: jpitts</div>
              <i class="fa far fa-lightbulb fa-1x" title="Like"></i>
                
                <div style={{ 
                marginTop:"20px",
                display:"flex",
                padding:'5px',
                borderRadius:'15px',
                border:'0.5px solid gray',
                width:'500px',
                background:'black',              
                color:'white'}}>2. : a sweet crystallizable material that consists wholly or essentially of sucrose, is colorless or white when pure tending to brown when less refined, is obtained commercially from sugarcane or sugar beet and less extensively from sorghum, maples, and palms, and is important as a source of dietary carbohydrate and as a sweetener and preservative of other foods

              </div>
              <div style={{display:"flex"}}>author: jdifelice</div>
              <i class=" far fa-lightbulb fa-1x" title="Like"></i>

            </Tab>
            <Tab label="Images">
                <div style={{marginTop:"50px"}}>{<VoteCarousel />}</div>
            </Tab>
            <Tab label="Pronunciations">

            </Tab>
            <Tab label="PartsOfSpeech">

            </Tab>
            </Tabs>
        );
    }
}

export default TabControl;