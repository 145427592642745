import React from 'react';
//import firebase from '../../Firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './WordBountyTable.css';

import WBActiveTableRows from '../word-bounty/WBActiveTableRows';
import WBCompletedTableRows from '../word-bounty/WBCompletedTableRows';

const config = require("../../config");

class WordBountyTable extends React.Component {
    
    constructor(props) {
        super(props);
       
               
        this.state = {
            tableJSX: ""
        };
        
      }

    componentDidMount = async (word) => {
        
        // var db = firebase.firestore();
        // let docBounty = db.collection("word-bounty");
        // let queryBounty = docBounty
        //     .where("status", "in", ["pending","started","completed"] );
        
                       
    }
      
      render() { 

        return(
            <Container style={{width:"100%"}} >
                <Row><Col className="col-12">&nbsp;</Col></Row>
            <Row>
                  <Col>
                    <h3>{config.app.verbiage.wordBountyPage.activeBounties}</h3>
                    <table id="tblWordBounty" class="table">
                        <thead>
                            <tr>
                                <th scope="col">Bounty</th>
                                <th scope="col">Word</th>
                                <th scope="col">Voting</th>
                                <th scope="col">Contest Ends</th>
                                <th scope="col">Sponsor(s)</th>
                            </tr>
                        </thead>
                        <WBActiveTableRows status="pending"/>
                    </table>

                    <h3>{config.app.verbiage.wordBountyPage.completedBounties}</h3>
                    <table id="tblWordBounty" class="table" style={{color:"white"}}>
                        {/* class="table table-striped table-dark"> */}
                        <thead>
                            <tr>
                                <th scope="col">Bounty</th>
                                <th scope="col">Word</th>
                                <th scope="col">Voting</th>
                                <th scope="col">Winner Paid</th>
                                <th scope="col">Sponsor</th>
                            </tr>
                        </thead>
                        <WBCompletedTableRows status="completed"/>
                    </table>
                </Col>
              </Row>
          </Container>  
          
        );
    }
}

export default WordBountyTable;
