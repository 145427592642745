import firebase from '../Firebase';
import { getUserPrivateKey } from '../constants/user-constants';
const config = require("../config");

const axios = require('axios');

var db = firebase.firestore();

const createToken = async(word, definitionId, toAddress, satAmount, fromPaymail) => {

    const tokenJson = await getTokenJSON(definitionId, toAddress, satAmount);
    let results = await axios.post(`${config.url.tokenService}/stas/contract/issue/${word}/${definitionId}`, tokenJson)
    .then(function (res) {
        console.log(res);
        saveToTokenLog(
            definitionId,
            res.data.transactions.issuerPrivateKey,
            "",
            res.data.transactions.contractTxId,
            "contract",
            toAddress,
            localStorage.getItem("senderPaymail"),
            "STAS",
            res.data.transactions.issueTxId,
            "issue",
            word
        )
      })
    .catch(error => {
        //element.parentElement.innerHTML = `Error: ${error.message}`;
        console.error('There was an error!', error);
    });

}

const getTokenIssueTxIdFromBlockchain = async(redeemAddress, symbol) => {
    var issueTxId = "";
    let results = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/token/${redeemAddress}/${symbol}`)
        .then(function (res) {
            issueTxId = res.data.token.issuance_txs[0];
        })
        .catch(error => {
            console.error('There was an error!', error);
        });

    return issueTxId;
}

const getTransferTokenJSON = async (
    word,
    definitionId,
    issuerPrivateKey, 
    ownerPrivateKey, 
    transferToAddress, 
    issueTxId) => {
    
        var json = {
        "word" : word,
        "definitionId" : definitionId,
        "isTestMode" : true,
        "issuerPrivateKey" : issuerPrivateKey,
        "ownerPrivateKey" : ownerPrivateKey,
        "transferToAddress" : transferToAddress,
        "issueTxId" : issueTxId    
    }

    return json;
}

const getRedeemTokenJSON = (
    issuerPrivateKey, 
    ownerPrivateKey,
    issueTxId,
    definitionId) => {
    
        var json = {
            "issuerPrivateKey" : issuerPrivateKey,
            "ownerPrivateKey" : ownerPrivateKey,
            "issueTxId" : issueTxId,
            "definitionId" : definitionId    
        }

    return json;
}


const getTokenJSON = async (definitionId, toAddress, satAmount) => {
    //let definitionId = "0b0d8017a8934cf996568c410c4dd09b";
    
    let json = await getComponentIds(definitionId); 
    let componentIds = json.componentIds;
    let word = json.word;

    var TokenJson = {
        "satAmount": satAmount,
        "toAddress" : toAddress,
        "definition" : {
            "definitionId" : "",
            "author" : "",
            "definitionText" : "",
            "defId" : definitionId,
            "usage" : "",
            "usageId" : "",
            "partOfSpeech" : "",
            "partOfSpeechId" : "",
            "audioUrl" : "",
            "audioData" : "",
            "audioId" : "",
            "imageUrl" : "",
            "imageData" : "",
            "imageId" : ""
        }
    }

    try{
        var docRef = db.collection("words").doc(word);
    
        const doc = await docRef.get();
        
        TokenJson.definition.author = doc.data().definitions[0].defVersions[0].author;

        for(let i=0; i<componentIds.length; i++){
            for(let j=0; j<doc.data().definitions[0].defVersions.length; j++){
                if(componentIds[i] === doc.data().definitions[0].defVersions[j].id){
                    TokenJson.definition.definitionText = doc.data().definitions[0].defVersions[j].definition;
                    //TokenJson.definition.defId =  definitionId; //doc.data().definitions[0].defVersions[j].id;
                    TokenJson.definition.usage = doc.data().definitions[0].defVersions[j].usage;
                }
                if(componentIds[i] === doc.data().definitions[0].images[j].id){
                    TokenJson.definition.imageUrl = doc.data().definitions[0].images[j].imgUrl;
                    TokenJson.definition.imageId =  doc.data().definitions[0].images[j].id;
                }
                if(componentIds[i] === doc.data().definitions[0].pronunciations[j].id){
                    TokenJson.definition.audioUrl = doc.data().definitions[0].pronunciations[j].audioUrl;
                    TokenJson.definition.audioId = doc.data().definitions[0].pronunciations[j].id;
                }
                if(componentIds[i] === doc.data().definitions[0].partOfSpeech[j].id){
                    TokenJson.definition.partOfSpeech = doc.data().definitions[0].partOfSpeech[j].value;
                    TokenJson.definition.partOfSpeechId = doc.data().definitions[0].partOfSpeech[j].id;
                }
            } 
        }

        console.log("TokenJson:",TokenJson);
        return TokenJson;
    } catch (error){
        console.log(error);
        throw error;
    }
}

const getComponentIds = async(definitionId) => {
    let json = {};
    const docRef = db.collection("word-definition-component-map")
        .where("definitionId","==",definitionId);
    
    const doc = await docRef.get();

    json = {componentIds : doc.docs[0].data().componentIds, word : doc.docs[0].data().word};
    return json;
}

const saveToTokenLog = (
    definitionId,
    issuerPrivateKey,
    fromPaymail,
    inputTxId,
    inputTxType,
    toAddress,
    toPaymail,
    protocol,
    txId,
    txType,
    word 
) => {

    db.collection("tokens").add({
        createDateTime : firebase.firestore.FieldValue.serverTimestamp(),
        definitionId : definitionId,
        issuerPrivateKey : issuerPrivateKey,
        fromPaymail : fromPaymail,
        inputTxId : inputTxId,
        inputTxType : inputTxType,
        toAddress : toAddress,
        toPaymail : toPaymail,
        protocol : protocol,
        txId : txId,
        txType : txType,
        word : word
        })
    .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
    });
}

const getTokens = async(paymail) => {
    var json = [];
        
    let docTokens = db.collection("tokens")
    .where("toPaymail", "==", paymail)
    .where("txType","==","issue");

    let snapshotTokens = await docTokens.get();

    snapshotTokens.forEach(function(item){
        json.push({
            "toPaymail" : item.data().toPaymail,
            "txId" : item.data().txId,
            "tokenType" : item.data().tokenType,
            "word" : item.data().word
        });
    });
  
    console.log("json:", json);

    return json;
}

const getTokenDetails = async(tokenId, ticker) => {
    let json = {};
    let result = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/token/${tokenId}/${ticker}`)

    json.contractTxId = result.data.token.contract_txs[0];
    json.issueTxId = result.data.token.issuance_txs[0];
    json.issuerPrivateKey = "";

    var db = firebase.firestore();
    var docRef = await db.collection("tokens")
        .where("txId","==",json.issueTxId)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                json.issuerPrivateKey = doc.data().issuerPrivateKey;
                //return json.issuerPrivateKey;
            });
        }).catch(function(error) {
            console.log(`Error getting : ${error}`);
        });

    return json;
}   
        
const transferToken = async(
    definitionId,
    word,
    issuerPrivateKey, 
    ownerPrivateKey, 
    transferToAddress, 
    issueTxId
    ) => {

    const tokenJson = await getTransferTokenJSON(
        word,
        definitionId,
        issuerPrivateKey, 
        ownerPrivateKey, 
        transferToAddress, 
        issueTxId
    );
    
    let results = await axios.post(`${config.url.tokenService}/stas/contract/issue/transfer`, tokenJson)
    .then(function (res) {
        console.log(res);
        saveToTokenLog(
            definitionId,
            issuerPrivateKey,
            "",
            issueTxId,
            "issue",
            transferToAddress,
            "",
            "STAS",
            res.data.transactions.transferTxId,
            "transfer",
            word
        )
      })
    .catch(error => {
        throw(error);
    });
}


const redeemToken = async(
    definitionId,
    word,
    issuerPrivateKey, 
    ownerPrivateKey, 
    issueTxId
) => {
    try{
        const tokenJson = getRedeemTokenJSON(
            issuerPrivateKey, 
            ownerPrivateKey, 
            issueTxId,
            definitionId
        );
        
        let results = await axios.post(`${config.url.tokenService}/stas/contract/issue/redeem`, tokenJson)
        .then(function (res) {
            //console.log(res);
            saveToTokenLog(
                definitionId,
                issuerPrivateKey,
                "",
                issueTxId,
                "redeem",
                "",
                "",
                "STAS",
                res.data.transactions.redeemTxId,
                "redeem",
                word
            )
            })
        .catch(error => {
            console.error('There was an error!', error);
            throw error;
        });

        return results;
    } catch (error) {
        console.log(error);
        throw error;        
    }
}

const getsubmitterPaymailAndWalletAddress = async(paymail) => {
    let db = firebase.firestore();
    var result = {
        submitterPaymail: "", 
        walletAddress: "",
        walletPrivateKey: ""
    };
    
    const query2 = await db.collection("wallets")
        .where("paymail","==",paymail)
        .where("network","==", "main")
        .get()
        .then(docs => {
            docs.forEach(doc => {
                result.walletAddress = paymail;
                result.walletAddress = doc.data().walletAddress;
                result.walletPrivateKey = doc.data().privateKey;
            })
        })
        
    return result;
}

export { getTokens, saveToTokenLog, createToken, getTokenIssueTxIdFromBlockchain,
            getTokenDetails, transferToken, redeemToken, getsubmitterPaymailAndWalletAddress }
