"use strict"

import firebase from '../Firebase';
var moment = require('moment');
var { wordBaronPriceLimit } = require('../constants/token-constants');
var db = firebase.firestore();

// const setProcessed = async () => {
//     const docRef = await db.collection("word-definition-wb-map")
//     .get()
//     .then( docs => {
//         docs.forEach( async doc => {
//             try{
//                 //console.log(`definitionId: ${doc.data().definitionId}, doc id: ${doc.id}`);
//                 // let wbRef = await db.collection("word-definition-wb-map")//.doc(doc.id)
//                 // .where("definitionId","==",doc.data().definitionId)
//                 // .update({
//                 //     processed: false
//                 // })
//                 const ref = doc(db,"word-definition-wb-map", "sdmU0WpJYADIHpEGA7b5");
//                 //const ref = doc.ref;//db.collection("word-definition-wb-map").doc(doc.id);
//                 await setDoc(ref, {"processed": false},{merge:true});
//                 // const res = await ref.update({
//                 //     "processed": false
//                 //     },
//                 // {merge:true});
//             } catch (error) {
//                 throw error;
//             }
//         });
//     })

// }

const addToActiveBounty = async(word, definitionId) => {
    var db = firebase.firestore();
    var wordBountyDocId;
    var returnJson = {
        "isWordBaron" : false, 
        "wordBountyDocId" : "",
        "paymail" : ""
    };

    const bounty = await db.collection("word-bounty")
        .where("status", "==", "pending")
        .where("word", "==", word)
        .orderBy("createDatetime","desc")
        .get()
        .then(function(querySnapshot){
            if(querySnapshot.size === 0){
                //no active bounties for the given word
                returnJson.isWordBaron = false;
                returnJson.wordBountyDocId = "";
            } else {
                for(let i=0; i < querySnapshot.docs.length; i++){
                    returnJson.isWordBaron = querySnapshot.docs[i].data().bounty >= wordBaronPriceLimit;
                    returnJson.wordBountyDocId = querySnapshot.docs[i].id;
                    returnJson.paymail = querySnapshot.docs[i].data().paymail;
                    break;
                }
            }
        })
        .catch(error => {
            console.log(error);    
        }); 
        //firebase.firestore.word-bounty.word.FieldValue.increment(1);
        if(returnJson.wordBountyDocId.length > 0) {
            db.collection("word-definition-wb-map").doc().set(
                {
                    definitionId: definitionId,
                    wordBountyDocId: returnJson.wordBountyDocId,
                    word: word,
                    isWordBaron : returnJson.isWordBaron,
                    processed: false
                }
            ).then(function() {
                console.log('successful logging');            
            }).catch(function(error) {
            console.log(error);
            });
        }

        return returnJson;
}

const addBounty = async(
    bounty,
    bountySatoshi,
    exchangeRate,
    txid,
    moneyButtonId,
    senderEmail,
    senderPaymail,
    voteStart,
    winnerPaymail,
    word,
    effectiveBounty,
    effectiveBountySatoshi
    ) => {
    
    var db = firebase.firestore();

    db.collection("word-bounty").add({
        bounty: bounty,
        bountySatoshi: bountySatoshi,
        exchangeRate: exchangeRate,
        status: "pending",
        txId: txid,
        moneyButtonId: moneyButtonId,
        submitterEmail: senderEmail,
        voteStart: "",
        completeDatetime: "",
        winner: winnerPaymail,
        word: word,
        createDatetime:  firebase.firestore.FieldValue.serverTimestamp(),
        paymail: senderPaymail,
        paid: false,
        effectiveBounty: effectiveBounty,
        effectiveBountySatoshi: effectiveBountySatoshi
    }).then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
        throw error;
    });
}  
    
 const getVoteStartText = (voteStart, wordExists) => {

    var timeUnits = "";
    var timeDiffDays = moment().diff(voteStart, 'days');
    var timeDiffHours = moment().diff(voteStart, 'hours');
    var timeDiffMinutes = moment().diff(voteStart, 'minutes');
    var timeDiffDisplay;
    var voteStartText = "";

    if (Math.abs(timeDiffHours) > 24){
        timeDiffDisplay = timeDiffDays;
        timeUnits = (Math.abs(timeDiffDays) === 1 ? "day" : "days"); 
    } else if (Math.abs(timeDiffHours) <= 1){
        timeUnits = (Math.abs(timeDiffMinutes) === 1 ? "minute" : "minutes");
        timeDiffDisplay = timeDiffMinutes;
    } else {
        timeUnits = (Math.abs(timeDiffHours) === 1 ? "hour" : "hours");
        timeDiffDisplay = timeDiffHours;
    } 

    if(!wordExists && timeDiffMinutes > 0){
        //if greater than zero, the voteStartDate is in the past
        voteStartText = "Awaiting 1st definition";
    } else if (wordExists && ( timeDiffMinutes < 0 )  ) {
        voteStartText = "Starts in " + Math.abs(timeDiffDisplay) + " " + timeUnits;
    } else if (wordExists && ( timeDiffMinutes >= 0 && Math.abs(timeDiffDays) <= 7 )  ) {
        voteStartText = "Vote Now!";
    } else if (wordExists && Math.abs(timeDiffDays) > 7 ){
        voteStartText = "Completed";
    } else {
        voteStartText = "Awaiting 1st definition";
    }

    return voteStartText;
}

export{addBounty, getVoteStartText, addToActiveBounty}