import React from 'react';
import BaseComponent from '../components/Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import pittsPic from '../assets/Pitts-photo.PNG'
import difelicePic from '../assets/DiFelice-photo.PNG'

class Mission extends BaseComponent {

  constructor(props) {
    super(props);
  }

  render() {

    return (

      <div id="parentContainer" className="content">
          <h2 className="howItWorksTitle">About</h2>
          <div className="col-md-8 col-md-offset-3" style={{ 
                padding:'5px',
                borderRadius:'15px',
                border:'0.5px solid black',
                width:'100%',
                background:'black',
                color:'white'}}>
            <h3 style={{alignContent:"center"}}>SLictionary is a product of SLCorp.</h3>
            <p>
            <table style={{width:"100%"}}>
              <tr>
              <td style={{verticalAlign:"top",paddingRight:"15px"}}>
                <img src={pittsPic}
                  alt=""/>
                <h3>John Pitts</h3>
                <p style={{paddingBottom: "10px"}}>John Pitts started having fun with words in his 20s living in an apartment with 2 surfer-dude roomates and an unabridged dictionary.  
                </p>
                <p style={{paddingBottom: "10px"}}>On Friday nights this group would page thru the dictionary looking for the ideal "word of the weekend."  
                </p>
                <p style={{paddingBottom: "10px"}}>The game from thence-on was to use that word as much as possible during social situations about town.  
                Breaking out the word-of-the-weekend in a crowded room within earshot of the other participants always brought a knowing wink from the other side of the room.  
                He noticed the dictionary could be a lot simpler and more efficient using web technology but never quite saw any new online dictionaries use his ideas.  
                Furthermore, He couldn't see how his ideas would make any money since his last name wasn't Merriam or Webster.  
                </p>
                <p style={{paddingBottom: "10px"}}>When BSV arrived he knew he had enough differentiating factors to make a go at making the best dictionary in the world-- one sourced by community and improved using money.  After watching the Wikipedia founder on stage in London, he knew what his mission was:  Attack from the flank-- words first-- proof of concept-- encyclopedia next.  John lives on an all-natural horse, chicken and hay farm in Furlong, Pennsylvania, codes iOS/Swift, and is devoting the rest of his life to BitCoin (SV) community in any way he can help.
                </p>
              </td>
              <td style={{width:"50%",verticalAlign:"top"}}>
                <img style={{width:"400px"}} src={difelicePic}/>
                <h3>John DiFelice</h3> 
              <ul>
              <li>A programmer with 25 years of experience.</li>
              <li>Proficient in .NET Core, C#, Java, Javascript, Angular, React, Backbone, REST Apis, SOA, LAMP.</li>
              <li>Owned and operated a software product company. </li>
              <li>Lived through the tech boom of the 90s. John never thought he'd experience something like that again, but here we are.</li>
              <li>Hobbies: John is a multi-instrumentalist who also writes fiction in his free time.</li>
              </ul>
            </td>
            </tr></table>
          </p>
          </div>
      </div>
    );
  }
}

export default Mission;