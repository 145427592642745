import React from 'react';
import BaseComponent from '../components/Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './WhyPay.css';

class Contact extends BaseComponent {

  constructor(props) {
    super(props);
  }
  
  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"yellow"}}>
          <h2>Contact Us</h2>
          <h3>email: SLictionaryLLC at gmail dot com</h3>

                    <h3>MoneyButton: johnpitts@moneybutton.com</h3>

          <h3>HandCash: $JOHNPITTS</h3>

          <h3>Twitter: @SLictionary.com</h3>

          <h3>Twetch & Twetch DM: @13328 or “SLictionary”</h3>
      </div>
    );
  }
}

export default Contact;