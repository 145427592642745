import axios from 'axios';
import firebase from '../Firebase';
import { createAddress } from './slictionary-bsv-mod';

const config = require("../config");

const getWalletInfo = async(paymail) => {
    var db = firebase.firestore();
    var arrReturn = {};
    var size; 
    
    var result = await axios.get(`${config.url.tokenService}/stas/walletAddress-privatekey`);
    
    var newlyCreated = false;
    let doc = await db.collection("wallets")
        .where("paymail","==", paymail)
        .where("network","==","main")
        .get()
        .then(docs => {
            size = docs.size;
            if(docs.size === 0){
                newlyCreated = true;
                arrReturn.walletAddress = result.data.walletAddress;
                arrReturn.privateKey = result.data.privateKey;

                db.collection("wallets").doc().set({
                    domain: "",
                    network: "main",
                    paymail: paymail,
                    privateKey: result.data.privateKey,
                    walletAddress: result.data.walletAddress
                }).then(function() {
                    console.log('wallet record created');            
                }).catch(function(error) {
                   console.log(error);
                });
            }
        }).catch(err => {
            console.log(err);
        });

        if(!newlyCreated){
            let doc2 = await db.collection("wallets")
            .where("paymail","==", paymail)
            .where("network","==","main")
            .get()
            .then(docs => {
                size = docs.size;
                if(docs.size !== 0){
                    
                    arrReturn.walletAddress = docs.docs[0].data().walletAddress;
                    arrReturn.privateKey = docs.docs[0].data().privateKey;
                }
            });
        }
    return arrReturn;
}

const getUserPrivateKey = async(username) => {
    var db = firebase.firestore();
    var privateKey = "";

    let doc = await db.collection("wallets")
        .where("paymail","==",username + "@moneybutton.com")
        .where("network","==","main")
        .get()
        .then(querySnapshot => {
            if(querySnapshot.size === 0){
                throw "Invalid username";
            } else {
                privateKey = querySnapshot.docs[0].data().privateKey;
                return privateKey;
            }
        })
        .catch(error =>{
            throw error;
        })
    
    return privateKey;
}

const impersonate = (paymail, walletAddress, privateKey) => {
    localStorage.setItem('senderPaymail',paymail);
    localStorage.setItem('walletAddress', walletAddress);
    localStorage.setItem('privateKey', privateKey);
}

export { getWalletInfo, getUserPrivateKey, impersonate };