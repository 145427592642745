const setDisplay = (imageExists, authorPaymail, loggedInPaymail) => {
    let result = "inline-flex";
    
    if(imageExists === null){
        if(authorPaymail === loggedInPaymail
            || isExternalLink()
        )
        {
            result = "none";
        }
    } else {
        if(authorPaymail === loggedInPaymail
            || isExternalLink()
            || !imageExists)
        {
            result = "none";
        }
    }

    return  result;
}

const isExternalLink = () => {
    console.log("document.referrer?", document.referrer);
    let url = document.referrer;
    const tmp = document.createElement('a');
    tmp.href = url;
    return tmp.host !== window.location.host;
};

export {setDisplay}