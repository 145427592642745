'use strict'


const postImage = async(instance, content, reply, branch, fileTxId, tweet, hide) => {
    instance.init();
    //let filesURL = `https://www.bitcoinfiles.org/t/${fileTxId}`;
    let filesURL = `https://media.bitcoinfiles.org/${fileTxId}`;
    
    let response = await instance.publish('twetch/post@0.0.1',{
        bContent: `${content}${branch}${filesURL}`,
        mapReply: reply,
        payParams: {
            tweetFromTwetch: tweet,
            hideTweetFromTwetchLink: hide
        }
    });
    console.log(response.txid);
}
//postImage(sdk, '', '', '', `https://media.bitcoinfiles.org/${fileTxId}`);
export {postImage};