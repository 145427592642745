import React,{useState, useEffect} from "react";  
import Modal from 'react-bootstrap/Modal';
import { getWalletInfo } from "../../mods/slictionary-user-mod";

const config = require("../../config");

var MoneyButton = require('@moneybutton/react-money-button').default;

function Login(props){  
    const [toggle, setToggle] = useState(false);

    const login = (e) => {
        setToggle(!toggle);
        e.preventDefault();
    };

    const logout = (e) => {
        if(localStorage["senderPaymail"] !== undefined){
            localStorage.removeItem("senderPaymail");
            localStorage.removeItem("walletAddress");
            localStorage.removeItem("privateKey");
        }
        sessionStorage.removeItem("wordsByUser");
        sessionStorage.removeItem("refreshedTestIt");
        document.getElementById("aLogout").style.display = "none";
        document.getElementById("aLogin").style.display = "inline";
        e.preventDefault();
        window.location.reload(true);
        window.location.assign("/");
    };

    const closeModal = () => {
        setToggle(false);
    }

    const onPayment = async(payment) => {
        try{
            localStorage.setItem("senderPaymail",payment.senderPaymail); 
            let results = await getWalletInfo(payment.senderPaymail);
            localStorage.setItem('walletAddress', results.walletAddress);
            localStorage.setItem('privateKey', results.privateKey);
            setToggle(false);
            document.getElementById("aLogin").style.display = "none";
            document.getElementById("aLogout").style.display = "inline";
            sessionStorage.setItem("refreshedTestIt", true);
            window.location.reload(true);
        } catch (error) {
            console.log(error.toString());
        }
    }

    return (
        <>
        <a id="aLogin" href="" onClick={(e) => login(e)} style={localStorage["senderPaymail"] === undefined ? {"display": "inline"} : {"display": "none"}}>{config.app.verbiage.login}</a>
        <a id="aLogout" href="" onClick={(e) => logout(e)} style={localStorage["senderPaymail"] === undefined ? {"display": "none"} : {"display": "inline"}}>{config.app.verbiage.logout}</a>
        {(
            <Modal show={toggle} onHide={closeModal}>
            <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
            <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>{config.app.verbiage.login}</Modal.Title>
            </Modal.Header> 
            <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
            <div id="modalLogin" style={{color:"white",backgroundColor:"#202020"}}>
                    <p>You must log into a MoneyButton account before 
                    you log into SLictionary. Swipe the button to log in.</p><br/>
                    <MoneyButton
                            to={"selflearningdictionary@moneybutton.com"}
                            amount={"0.001"}
                            currency={"USD"}
                            onPayment={onPayment}
                            label={config.app.verbiage.login}
                            buttonId="SLictionary"
                            buttonData=""
                            opReturn="SLic"
                        />
                </div>
            </Modal.Body>
        </Modal>
      )}
      </>

    )
}  

export default Login;  