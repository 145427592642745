//SimpleSlider.js
import React, { Component } from "react";
import firebase from '../../Firebase';
import {getDefinitions, getImages, getPronunciations, getPartsOfSpeech} from './Version';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./SimpleSlider.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {getVersionVoteCounts} from './Vote';
import {imageBrightness,textColors} from '../../constants/word-constants';
import {setDisplay} from "../../mods/slictionary-control-mod";

const {addVote} = require('../../mods/slictionary-vote-mod'); 
const config = require("../../config");

var _currentSlideIndex = 5;
var _pronunciations = [];
var _partsOfSpeech = [];
var _arrVoteCountsById = [];
var _imageExists = false;

const beTheFirstAudioUrl = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/audio%2FBe%20the%20First.m4a?alt=media&token=611721e1-3ac6-45cb-ae09-0526976068c1";
const beTheFirstAudioUrl2 = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2FNew%20Recording%2011.m4a?alt=media&token=fdef7d6f-6c03-470e-9012-e78bb8545c59";

export default class SimpleSlider extends Component {

    constructor(props) {
        super(props);
        this.postChange = this.postChange.bind(this);
        this.bulbClick = this.bulbClick.bind(this);
        this.bulbProcess = this.bulbClick.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

        //this.alertMessage = this.alertMessage.bind(this);

        this.state = {
            order:0,
            imgUrl:"",
            pronunciation:"",
            definition:"",
            rows: [],
            audioSrc: "",
            pronunciations: [],
            isOpen: false,
            show: false,
            setShow: false,
            imgSrc:"",
            currentSlide:"" 
          };
          
          this.handleClose = this.handleClose.bind(this);
          this.handleShow = this.handleShow.bind(this);

          this.cool();
    }

    // alertMessage = () => {
    //     alert("Hello!");
    // }
    setModalState(showModal) {
        this.setState({
            showModal: showModal
        });
    }

    bulbClick = event => {
        var arr = event.target.id.split("|");

        if(arr[1] !== localStorage.getItem("senderPaymail")){ //this prevents an author for casting a vote for him/herself
            var arrBulbs = document.getElementsByTagName('i');
            for(var i = 0; i < arrBulbs.length; i++){
                if (arrBulbs[i].className === "fa fa-lightbulb fa-1x column"){
                    return;
                }
            }

            event.target.style.color = "yellow";
            event.target.className = "fa fa-lightbulb fa-1x column";

            let id = arr[0];
            let author = arr[1];
            let voter = localStorage.getItem("senderPaymail");
            addVote(id, this.props.word.toLowerCase(), author, voter);
        }
    }

    beforeChange = event =>{
      this.slider.slickGoTo(0);
    }

    getCurrentSlideIndex(){
      return _currentSlideIndex;
    }

    postChange = eventCurrentSlide => {
      if(eventCurrentSlide === -1){eventCurrentSlide = 0;}
        let key = _arrVoteCountsById[eventCurrentSlide].key;
        let audioUrl = "";

        if(_pronunciations[key].audioUrl === beTheFirstAudioUrl
            || _pronunciations[key].audioUrl === beTheFirstAudioUrl2 
          ){
          audioUrl = "";
        } else {
          audioUrl = _pronunciations[key].audioUrl;
        }
        
        let jsonReturn = {
          "audioUrl":audioUrl,
          "audioId":_pronunciations[key].id,
          "audioAuthor":_pronunciations[key].author
        } 
        this.props.parentCallback(jsonReturn);

    }

    handleShow = event => {
      this.setState({imgSrc:event.target.src});
      this.setState({show:!this.state.show});

      return false;
    }

    handleClose = () => {
        this.setState({show:!this.state.show});
    }

    cool = async () => {
        var db;
  
        db = firebase.firestore();
  
        let wordLower = this.props.word.toLowerCase();      
        var docRef = db.collection("words").doc(wordLower);
  
        const doc = await docRef.get();
        //let json = doc.data();
        let definitions = await getDefinitions(wordLower,null,doc);
        let images = await getImages(wordLower,null,doc);
        _pronunciations = await getPronunciations(wordLower,null,doc);
        _partsOfSpeech = await getPartsOfSpeech(wordLower, null, doc);
        
        var s;
        let bulbLeft = "";
        
        if(definitions.length === 1){
            bulbLeft = "325px";
        } else {
            bulbLeft = "-50px";
        }

        _arrVoteCountsById = await getVersionVoteCounts(wordLower);
        
        let loopIndex = 0;
        for (var index in _arrVoteCountsById) {

          //let imageBright = "brightness(" + imageBrightness[loopIndex] + "%)";
          let _imageBrightness = imageBrightness;

          let key = _arrVoteCountsById[index].key.toString();
            
          //var item = definitions[key];

          //This code is to hide the default 'no image' image. The hidden class is defined
          //on the TestIt.css page
          var hiddenClass = "";
          if( images[key].imgUrl === "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2Fno%20image.jpg?alt=media&token=f4c3f74a-30c0-4a5e-8f52-67fea1cf13f1"){
            images[key].imgUrl = "";
          }
          
          if(images[key].imgUrl === ""){
              hiddenClass = "text-center hidden";
              _imageExists = false;
            }else {
              hiddenClass = "text-center";
              _imageExists = true;
          }

          var hiddenExampleClass = "";
          if(definitions[key].usage === ""){
            hiddenExampleClass="hidden";
          } else {
            hiddenExampleClass = "";
          }
         
        //_partsOfSpeech[key].value = "";
        var partsOfSpeechDisplay = "";
        if(_partsOfSpeech[key].value.length > 0){
            partsOfSpeechDisplay = "[" + _partsOfSpeech[key].value + "]";
        } 
          
          let className = "column imagePanel brightness-" + imageBrightness[loopIndex].toString();
          let fontColor = textColors[loopIndex];
            s = this.state.rows.concat (
                <div style={{border:'0.5px solid gray'}}>
                <Container>
                    <Row className={hiddenClass} >
                        <Col>
                            
                            <img className={className}
                                style={{
                                    display:"inline"
                                    ,whiteSpace:"nowrap"
                                    ,position:"relative"
                                    ,cursor: "pointer"
                                    //,filter: "blur(10px)" // this is to make images fuzzy for content we want people to pay extra to see
                                }}
                                src={images[key].imgUrl}
                                onClick={this.handleShow}
                                alt=""
                            />

                            <i style={{
                                display:setDisplay(_imageExists, definitions[key].author, localStorage.getItem("senderPaymail"))
                                ,color:"white"
                                ,verticalAlign:"top"
                                ,position:"relative"
                                ,left:"0px"
                                ,top:"0px"
                                ,backgroundColor:"black"
                                ,width:"20px"}} 
                              id={images[key].id + "|" + images[key].author}
                              onClick={this.bulbClick}
                              className="far fa-lightbulb fa-1x column" title="Vote">
                            </i>
                        </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row className="" dir={config.app.lang === "msa" ? "rtl" : "ltr"}>
                    <Col>
                    
                    <span className="" id="sp0" style={{color:fontColor}}>
                    <span id="partsOfSpeech"><em>{partsOfSpeechDisplay}</em></span>
                        <p dir={config.app.lang === "msa" ? "rtl" : "ltr"}>
                            {definitions[key].definition}
                        </p>
                        <i style={{color:"white",display:setDisplay(null, definitions[key].author, localStorage.getItem("senderPaymail"))}} 
                        id={definitions[key].id + "|" + definitions[key].author}
                        onClick={this.bulbClick} 
                            className="far fa-lightbulb fa-1x column" title="Vote"></i>
                            <div>&nbsp;</div>
                        <div style={{fontSize:"12pt"}} className={hiddenExampleClass}>
                        <i>Used in a sentence:</i>
                        </div>
                        <div 
                            style={{fontSize:"12pt",color:fontColor}} 
                            className={hiddenExampleClass} dir={config.app.lang === "msa" ? "rtl" : "ltr"}>
                        <i>{definitions[key].usage}</i>
                        <i style={{color:"white",display:setDisplay(null, definitions[key].author, localStorage.getItem("senderPaymail"))}} onClick={this.bulbClick}     
                        id={definitions[key].id + "|" + definitions[key].author}
                            className="far fa-lightbulb fa-1x column" title="Vote"></i>
                            </div>
                     {<div>&nbsp;</div>}
                    <div style={{fontSize:"9pt"}}> - {definitions[key].author}</div>
                    </span>
                    </Col>
                </Row>

                
                </Container>
            </div>
            
            );
            
            this.setState({rows: s});

            if(loopIndex < _imageBrightness.length-3){
              loopIndex += 1;
            }
            
      }
        this.setState({rows: s});
      }

      onAudioClick = (event) => {
        let audioId = event.target.id.split("|")[1];
        if(document.getElementById(audioId).paused){ // checks if element is playing right now
            document.getElementById(audioId).play(); return false;
        } else {

            document.getElementById(audioId).pause(); return false;
        }
        
    }


    toggleModal = () => {
      alert(this.state.isOpen);
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
    
    render() {
        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "300px",
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            //beforeChange: this.beforeChange,
            beforeChange:(current,next) => {
              //try{
                if(this.slider){
                  this.slider.slickGoTo(0);
                }
              //} catch (error){
              //  console.log(error);
              //}
              },
            afterChange: this.postChange
            ,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    infinite: true,
                    dots: true

                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1
                  }
                },
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1

                  }
                }
              ]
        };

    return (
      <div >
        <Modal width="90%" height="90%" show={this.state.show} onHide={this.handleClose} style={{border:"1px"}} size="lg">
                  {/* <Modal.Header closeButton style={{backgroundColor:"#2f2f2f"}}>
                    <Modal.Title style={{backgroundColor:"black"}}></Modal.Title>
                  </Modal.Header> */}
                  <Modal.Body className="center" style={{backgroundColor:"black"}}>
                      <div style={{backgroundColor:"black"}}>
                        <img id="modalImage" alt="" src={this.state.imgSrc}/>

                      </div>

                  </Modal.Body>
                  <Modal.Footer style={{backgroundColor:"black"}}>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                  </Modal.Footer>
              </Modal>
        <Slider ref={slider => (this.slider = slider)} {...settings}>{this.state.rows}</Slider>
      </div>     
    );
  }
}
