import firebase 
    from '../../Firebase';
import {gimmeDoc} from './Document';
import { getDefinitions, getImages, getPronunciations } from './Version';
//import {sortByProperty} from '../../mods/slictionary-util-mod';
var arrFinal = [];

const getVoteSum = async (word) => {

    const snapshot = await firebase.firestore().collection('votes')
        .where("word", "==", word)
        .get();
        
    return snapshot.docs.map(doc => doc.data);
}

const getVersionVoteCounts = async (word) => {
    let docRef = await gimmeDoc(word);
    let arrFinal = [];
    try{
        
        let defs = await getDefinitions(word, "", docRef);
        let pronunciations = await getPronunciations(word, "", docRef);
        let images = await getImages(word, "", docRef);

        let arrVoteCount = [];

        for(let i=0; i < defs.length; i++){
            try{
                arrVoteCount.push({"key":i,"id": defs[i].id,"count":0});
            } catch(error){
                console.log(error);
            }

            try{
                arrVoteCount.push({"key":i,"id": images[i].id,"count":0});
            } catch(error){
                console.log(error);
            }

            try{
                arrVoteCount.push({"key":i,"id": pronunciations[i].id,"count":0});
            } catch(error){
                console.log(error);
            }
        }

        let arrVoteId = await getVotes(word);

        for(let i=0; i < arrVoteCount.length; i++){
            arrVoteCount[i].count = arrVoteId[arrVoteCount[i].id];
            if(arrVoteCount[i].count == undefined){
                arrVoteCount[i].count = 0;
            }
        }

        arrVoteCount.sort(sortByProperty("key")); 

        let arrReturn = [];
        for(let i=0; i < arrVoteCount.length; i++){
            arrReturn.push({"key":arrVoteCount[i].key,"count":arrVoteCount[i].count});
        }

        let count = 0;
        let key = 0;//arrReturn[0].key;
        let previousKey = arrReturn[0].key;
        for(let i=0; i < arrReturn.length; i++){
            key = arrReturn[i].key;
            
            if (key == previousKey){
                count = count + arrReturn[i].count;
            } else {
                arrFinal.push({"key":previousKey,"count":count});
                count = 0;
                count = count + arrReturn[i].count;
            }
            previousKey = key;
        }
        arrFinal.push({"key":key,"count":count});

        arrFinal.sort(sortByProperty("count"));
    } catch (error){
        //the word does not exist. Default to count = 0
        arrFinal.push({"key":0,"count":0});
        arrFinal.sort(sortByProperty("count"));
    } 
    
    return arrFinal;
}

function sortByProperty(property){  
    return function(a,b){  
       if(a[property] < b[property])  
          return 1;  
       else if(a[property] > b[property])  
          return -1;  
   
       return 0;  
    }  
 }

const getVotes = async (word) => {

    let votes = [];
    const results = await firebase.firestore().collection("votes")
        .where("word", "==", word)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach(function(doc) {
                votes.push(doc.data().Id);
            });
        });
  
        let array = votes,
        arrCount = array.reduce((a, c) => (a[c] = (a[c] || 0) + 1, a), Object.create(null));
            
        return arrCount;
}

export  { getVoteSum, getVersionVoteCounts };
