//TestIt.js
import ReactDOM from "react-dom";
import React, { Component} from 'react';
import firebase from '../Firebase';
import {getPronunciations, getPartsOfSpeech} from './Common/Version';
import {gimmeDoc} from './Common/Document';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SimpleSlider from './Common/SimpleSlider';
import './TestIt.css';
import {getVersionVoteCounts} from '../components/Common/Vote';
import {addErrorToLog} from '../mods/slictionary-error-log-mod';
import { setDisplay } from "../mods/slictionary-control-mod";
import {isWordAuthenticated} from "../components/Common/Words";

const beTheFirstAudioUrl = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/audio%2FBe%20the%20First.m4a?alt=media&token=611721e1-3ac6-45cb-ae09-0526976068c1";
const beTheFirstAudioUrl2 = "https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/words%2FNew%20Recording%2011.m4a?alt=media&token=fdef7d6f-6c03-470e-9012-e78bb8545c59";

const {addVote} = require('../mods/slictionary-vote-mod'); 

var refreshedTestIt;
var _pronunciations;
var _partsOfSpeech = [];
var _firstAudio;
var _authors = [];
var _defId;
var _docRef;
var _hasError;
var audioId = "audio_play_0";
var _selectedAudioId;
var _selectedAudioAuthor;
var _arrVoteCountsById = [];


class TestIt extends Component {

    constructor(props) {
      super(props);
      //try{
        //this.onAuthorClick = this.onAuthorClick.bind(this);
        this.bulbProcess = this.bulbClick.bind(this);

        let arr = this.props.location.pathname.split("/");
          
        this.state={
          docRef:null,
          tmp:"",
          phonetic:"",
          word: arr[2],
          txid: arr[3],
          defId: "",
          pronunciations: [],
          authors: [],
          hasError: false,
          hasAudioFiles: false,
          firstAudio: "",
          firstAuthor: "",
          selectedAudio: "",
          arrVoteCountsById: [],
          refreshed: false
        }


    }

    callbackFunction = (childData) => {
      let srcUrl = childData.audioUrl;
      _selectedAudioId = childData.audioId;
      _selectedAudioAuthor = childData.audioAuthor;
      let hasAudioFiles = this.getHasAudioFiles(_pronunciations);

      if(hasAudioFiles){
        if(childData.audioUrl === beTheFirstAudioUrl){
          srcUrl = "";
        }
      } else {
        srcUrl = beTheFirstAudioUrl;  
      }

      try{
        if(srcUrl.length === 0){
          let d = document.getElementById("audioControls");
          d.style.display = "none";
        }else {
            let d = document.getElementById("audioControls");
            d.style.display = "inline";
        }
      } catch(error){
        let d = document.getElementById("audioControls");
        d.style.display = "none";
      }

      document.getElementById(audioId).src = srcUrl;
    }
    
    shouldComponentUpdate(nextProps)
    {
      return false;
    }

    getHasAudioFiles(pronunciations){
      let count = 0;
      for(let i=0;i < pronunciations.length; i++){
        if(pronunciations[i].audioUrl === "" 
            || pronunciations[i].audioUrl === beTheFirstAudioUrl){
              // do nothing
            } else {
              count += 1;
            }              
      }

      return count !== 0;
    }

    // static getDerivedStateFromProps (props, state) {
    //     _arrVoteCountsById = getVersionVoteCounts(this.state.word.toLowerCase());
    //     return {arrVoteCountsById: _arrVoteCountsById}; 
    
    // }

    async componentWillMount(){
        document.body.style.backgroundImage = "url('')";
        if(Boolean(sessionStorage["refreshedTestIt"])===false){
            sessionStorage.setItem("refreshedTestIt", true);
            window.location.reload(true);
        }
    }

    async componentDidMount(word) {
        //sessionStorage.setItem("refreshedTestIt", false);
        // PUT THIS BACK LATER. Removed 03-17-2021
        // let isAuthenticated = await isWordAuthenticated(this.state.word,this.state.txid); 
        // if(!isAuthenticated){
        //     addErrorToLog("invalid transaction Id: " + this.state.txid,"error");
        //     this.props.history.push("/");
        //     this.props.history.push({pathname:"error-pages/error-invalid-transaction"});
        // }


        let doc = document.referrer;
        let i = 0;
        
      try{
        
        var db;
        db = firebase.firestore();
        let wordLower = this.state.word.toLowerCase(); 
        var docRef = db.collection("words").doc(wordLower);
        const doc = await docRef.get();

        _arrVoteCountsById = await getVersionVoteCounts(wordLower);
        this.setState({arrVoteCountsById: _arrVoteCountsById})

        this.createAudioPlayer();

        _pronunciations = await getPronunciations(wordLower,null,doc);
        _partsOfSpeech = await getPartsOfSpeech(wordLower,null,doc);
        
        if(this.getHasAudioFiles(_pronunciations)){
          let key = _arrVoteCountsById[0].key;
          if(_pronunciations[key].audioUrl === beTheFirstAudioUrl
                || _pronunciations[key].audioUrl === beTheFirstAudioUrl2
              ){
                _firstAudio = "";
              } else {
                _firstAudio = _pronunciations[key].audioUrl;
              }
          _selectedAudioId = _pronunciations[key].id;
          _selectedAudioAuthor = _pronunciations[key].author;
        } else {
          _firstAudio = beTheFirstAudioUrl;
        }

        let audioDisplay = setDisplay(null, _selectedAudioAuthor, localStorage.getItem("senderPaymail"))
        let dd = document.getElementById("audioBulb");
        dd.style.display = audioDisplay;

            if(_firstAudio === ""){
          let d = document.getElementById("audioControls");
          d.style.display = "none";
        }

        document.getElementById(audioId).src = _firstAudio;
        
        this.cool();

        let _word = this.state.word;
        if(this.isAbbreviation(_partsOfSpeech)){
            document.getElementById("title").innerHTML = _word.toUpperCase();
        } else {
            document.getElementById("title").innerHTML = _word;
        }

        let d = document.getElementsByClassName("hidden");
        d.display = "none";

        this.setState({authors:_authors});
        
        let btn = document.getElementById("IcanDoBetter");
        btn.style.display = "inline";

        let img = document.getElementById("img|audio_play");
        img.style.display = "inline";

        let bulb = document.getElementById("audioBulb");
        //bulb.style.display = "inline-flex";
        //throw new Error("test");
    } catch (error){
        addErrorToLog("message: " + error.message + "\n stack: " + error.stack,"error")
        //this.props.history.push("/");
        //this.props.history.push({pathname:"error-pages/error-display-message"});
    }
  }

//   addVote = (id, author) => {
//     var db;

//     db = firebase.firestore();
//     db.collection("votes").add({
//         Id: id,
//         author: author,
//         datetime:  firebase.firestore.FieldValue.serverTimestamp(),
//         status: "pending",
//         txId: "",
//         voter: "",
//         word: this.state.word.toLowerCase()
//     }).then(function(docRef) {
//         //console.log("Document written with ID: ", docRef.id);
//     })
//     .catch(function(error) {
//         //console.error("Error adding document: ", error);
//     });
    
// }
  
    bulbClick = event => {
      var arrBulbs = document.getElementsByTagName('i');
      for(var i = 0; i < arrBulbs.length; i++){
          if (arrBulbs[i].className == "fa fa-lightbulb fa-1x column"){
              return;
          }
      }

      event.target.style.color = "yellow";
      event.target.className = "fa fa-lightbulb fa-1x column";

      //this.addVote(_selectedAudioId,_selectedAudioAuthor);
      let voter = localStorage("senderPaymail");
      addVote(_selectedAudioId, this.state.word.toLowerCase(), _selectedAudioAuthor, voter);
  }

    isAbbreviation = (partsOfSpeech) => {
        var isAbbreviation = false;
        for(let i=0; i < partsOfSpeech.length; i++){
            if(partsOfSpeech[i].value === "abbreviation"){
                isAbbreviation = true;
            }
        }
        return isAbbreviation;
    }

    async cool(){
     
      let word = this.state.word;
      let docRef = await gimmeDoc(word);
      let json = docRef.data();
      _defId = json.definitions[0].id === "" ? "@" : json.definitions[0].id;

      _docRef = docRef;

    }

    onAudioClick = () => {
        document.getElementById(audioId).play();
    }

    addVersionOnClick = () =>{
      this.props.history.push("/");
      this.props.history.push({
        
        pathname:"createword/" + this.state.word + "/" + _defId + "/txid"
      });
    }

    addWordOnClick = () =>{
      this.props.history.push("/");
      this.props.history.push({
        pathname:"createword/"
      });
    }

    componentDidCatch(error, info) {    
      _hasError = true;
    }

    createAudioPlayer(){
        try{
            var x = document.createElement("AUDIO");
            let parent = document.getElementById("parentContainer")
            x.id = audioId;
            x.setAttribute("controls", "controls");
            x.setAttribute("preload","none");
            x.style.display="none";
            let source= document.createElement('source');
            if(x.canPlayType('audio/mpeg;')){
            source.type = "audio/mp3";
            } else {
            source.type = "audio/mp4";
            }
            //source.type= 'audio/mp4';
            source.src= "";
            x.appendChild(source);
            parent.appendChild(x);      
        } catch (error) {
            addErrorToLog(error,"error");
        }
    }


render() {
return (

<Container id="parentContainer"> 
    <Row className="row justify-content-start text-center">
        <Col className="col-11">
          <span id="title" className="inline" style={{backgroundColor:"black", color:"white",fontFamily:"Cambria", fontSize:"30pt"}}  />
          <span id="audioControls">
              <span>
                      <img id={"img|audio_play"} className="liveimage" 
                          style={{width:"30px",display:"none", marginTop:"-12px"}} 
                          src="https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/audio-blue.png?alt=media&token=907e66b7-3bb5-4657-8f72-a984d6274049"
                          onClick={this.onAudioClick} />
              </span>
              <span>
                    <i id="audioBulb" name="audioBulb" style={{color:"white", display:"none"}} onClick={this.bulbClick} 
                        className="far fa-lightbulb fa-1x column" title="Vote"></i>
              </span>
          </span>
        </Col>
    </Row>
    <Row >
      <Col className="col-12">
        <div id="demo" className="carousel slide" data-ride="carousel">
            <div style={{marginTop:"15px"}}>
              {<SimpleSlider 
                    parentCallback = {this.callbackFunction} 
                    ref={simpleslider => (this.simpleslider = simpleslider)}  
                    word={this.state.word} 
                />}
            </div>
        </div>
      </Col>
    </Row>
    <Row><Col>&nbsp;</Col></Row>
    <Row>
      <Col className="col-12 text-center">
        <button id="IcanDoBetter" className="btn btn-dark" 
            style={{width:"150px",zIndex:"1",color:"#81BEF7",display:"none"}} 
            onClick={this.addVersionOnClick}>
          I can do better
          </button>
      </Col>
    </Row>
    <Row><Col className="col-12">&nbsp;</Col></Row>
    <Row><Col className="col-12">&nbsp;</Col></Row>
    <Row><Col className="col-12">&nbsp;</Col></Row>
  </Container>
  
);
}
}

export default TestIt;