
import React, { Component } from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import lightbulb from '../../assets/lightbulb-black-background.jpg';

export default class StatsSlideshow extends Component {

    constructor(props) {
        super(props);
    }

    
        render() {
            const properties = {
                duration: 5000,
                transitionDuration: 500,
                infinite: true,
                autoplay: false,
                prevArrow: <div style={{width: "30px", marginRight: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></div>,
                nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></div>
              };

            return (
        <div style={{textAlign:"center"}}>
            <Slide {...properties} easing="ease" style={{width:"400px"}}>
            <div className="each-slide">
                <div style={{fontFamily:"Permanent Marker", fontSize:"20pt"}}>Times Lit!</div>
                <div>
                <img src={lightbulb}
                    style={{verticalAlign:"bottom","width":"100px"}}/>
                <span style={{"fontFamily":"Shadows Into Light", "fontSize":"58pt", "color":"yellow"}}>{this.props.voteCount}</span>
                <img src={lightbulb}
                    style={{verticalAlign:"bottom","width":"100px"}}/>
                </div>
            </div>
            <div className="each-slide">
                <div style={{fontFamily:"Permanent Marker", fontSize:"20pt"}}>Earnings</div>
                <span style={{"fontFamily":"Pacifico", "color":"#66ff00","fontSize":"24pt"}}>{(this.props.voteEarnings/1000).toFixed(3).toLocaleString()} ksat</span>
           </div>
            </Slide>
        </div>
        );
    }
}