import React from 'react';
import firebase from '../../Firebase';
import './WordBountyTable.css';
import {wordExists} from '../Common/Words';
import { updateWBStatus } from '../../services/firebase/database';
import {getVoteStartText} from '../../mods/slictionary-word-bounty-mod';
import {sortByMultiple} from '../../mods/slictionary-util-mod';
import { getWalletInfo } from '../../mods/slictionary-user-mod';


var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;

var moment = require('moment');


class WBCompletedTableRows extends React.Component {
    
    constructor(props) {
        super(props);
       
        this.state = {
            tableJSX: "",
            rows: [],
            rowWord: "",
            rowVoteStart: "",
            rowHeader: "",
            table: []
        };
        this.myOnPaymentCallback = this.myOnPaymentCallback.bind(this);
        this.setSponsorText = this.setSponsorText.bind(this);
        this.aggregateWBdata = this.aggregateWBdata.bind(this);
    }

    componentDidMount = async (word) => {
        try {
            var s = "";
            
            var db = firebase.firestore();
            let docBounty = db.collection("word-bounty")
                .where("status", "==", this.props.status)
                .orderBy("bounty", "desc")
                .orderBy("word", "asc");

            let snapshotBounty = await docBounty.get();
            var bWordExists = false;
            
            let objBounty = this.aggregateWBdata(snapshotBounty);
            
            objBounty.forEach(function(item){
                item.bounty = Number(item.bounty.toFixed(2));
            });

            var objBounty1 = sortByMultiple(objBounty);
            //console.log(objBounty);

            for(var index = 0; index < objBounty.length; index++){
                
                //let doc = snapshotBounty.docs[index];
                let doc = objBounty[index];
                //bWordExists = doc.data().wordExists === undefined ? false : doc.data().wordExists;
                if(this.props.status === "completed"){
                    bWordExists = true;
                } else {
                    bWordExists = await wordExists(doc.word);
                }
                var voteStartText = "";
                var bountyText = "";
                var contestEndsText = "";
                
                try{
                    var timeDiffDays = moment().diff(doc.voteStart, 'days');
                    contestEndsText = moment(doc.voteStart, "DD-MM-YYYY").add(7, 'days');
                    voteStartText = getVoteStartText(doc.voteStart, bWordExists);

                    this.setState({rowWord:<a  style={{color:'#98CBFF'}}
                            href={"/createword/" + doc.word + "/@/@"}>
                                {doc.word} </a>});
                
                    this.setState({rowVoteStart:voteStartText});

                    let username = this.setSponsorText(doc.paymail);

                    let bountyNumber = Number(doc.bounty);
                    if(bountyNumber < 0.9999){
                        bountyNumber = bountyNumber * 100;
                        let currencyUnit = "\u00A2"; 
                        bountyText = bountyNumber.toFixed().toString() + currencyUnit;
                    } else {
                        bountyText = "$" + bountyNumber.toFixed(2).toLocaleString()
                    }

                    var winner = "";
                    /*** Winner Paid */
                    if(doc.winner.length > 0){
                        var arrWinners = [];
                        if(doc.winner.indexOf(",") === -1){
                            arrWinners.push(doc.winner);
                        } else {
                            let arrTemp = doc.winner.split(",");
                            for(let k = 0; k < arrTemp.length; k++){
                                arrWinners.push(arrTemp[k]);
                            }
                        }
                        var winnerText = "";
                        for(let i = 0; i < arrWinners.length; i++){
                            var arr2 = arrWinners[i].split("@");
                            winnerText += i===0 ? arr2[0] : "," + arr2[0];
                        }
                        //var arr2 = doc.winner.split("@");
                        //var winner = arr2[0];
                        winner = winnerText;
                    }
                    /******************************* */

                    let mb;
                    
                    if (voteStartText === "Vote Now!"){
                        mb = <MoneyButton
                                to="selflearningdictionary@MoneyButton.com"
                                amount="0.0096"
                                currency="USD"
                                onPayment={this.myOnPaymentCallback.bind(payment)}
                                label="to Vote"
                                onLoad={this.onMoneyButtonLoad}
                                buttonId={doc.word}
                                buttonData="{app: SLictionary}"
                                opReturn="SLic"
                        />
                    } else {
                        mb = this.state.rowVoteStart;
                    }

                    s = this.state.rows.concat (

                        <tr scope="row">
                            <td>
                                {bountyText}
                            </td>
                            <td >
                                {this.state.rowWord}
                            </td>
                            <td>
                                {mb}
                            </td>
                            <td >{winner}</td>
                            <td >{username}</td>
                        </tr>
                    )  

                    this.setState({rows: s})    

                    if (bWordExists && Math.abs(timeDiffDays) > 7 && doc.status != "completed"){
                        await updateWBStatus(doc.word,"completed");
                        
                        console.log("wbDocId:", doc.id);
                        //create tokens for each WB definition
                        
                    }

                } catch (e) {
                    console.log("Error:",e);
                }      
            };

        }catch(error){
            console.log(error);
        }
    }
      
    async myOnPaymentCallback(payment){
        localStorage.setItem("senderPaymail",payment.senderPaymail);
        if(localStorage["walletAddress"] === undefined){
            let results = await getWalletInfo(payment.senderPaymail);
            //localStorage.setItem('senderPaymail',payment.senderPaymail);
            localStorage.setItem('walletAddress', results.walletAddress);
            localStorage.setItem('privateKey', results.privateKey);
        }
    
        window.location.assign("/testit/" + payment.buttonId + "/txid");
    }

    navigateToVote(word){
        window.location.assign("/testit/" + word + "/txid");
    }
    
    setSponsorText = (paymailString) => {
        let arr = paymailString.split(",");
        let arr2 = [];
        let sponsorText = "";

        if(arr.length === 1){
            //comma delimiter not found
            arr2 = arr[0].split("@")
            sponsorText = arr2[0];
        } else {
            for(let i=0; i < arr.length; i++){
                arr2 = arr[i].split("@");
                sponsorText = i===0 ? arr2[0] : sponsorText + ", " + arr2[0];
            }
        }   
        
        return sponsorText;
    }
    
      render() { 

        return(
            
         <tbody>{this.state.rows}</tbody>
                
        );
    }

    aggregateWBdata = (snapshotBounty) => {
        var objTotal = [];
        var obj = [];
        for(var index = 0; index < snapshotBounty.size; index++){
            
            let doc = snapshotBounty.docs[index];
            let json = doc.data();
            json.id = snapshotBounty.docs[index].id;
            obj.push(json);
        }
        var obj1 = JSON.parse(JSON.stringify(obj));
        var needle = '';
        var sum = 0;
        var paymail = "";
        var status = "";
        var voteStart = "";
        var winner = "";
        var id = "";
        
        for(var i=0; i < obj.length; i++){
            //this is for the decrement of 1.0 for bounties >= 2 && <= 11
            // if (obj1[j].effectiveBounty !== undefined){
            //     obj1[j].bounty = obj1[j].effectiveBounty;
            // }
            paymail = "";
            winner = "";
            needle = obj[i].word;
            sum = 0;
            for (var j = 0; j < obj1.length; j++){
                //this is for the decrement of 1.0 for bounties >= 2 && <= 11
                if (obj1[j].effectiveBounty !== undefined){
                    obj1[j].bounty = obj1[j].effectiveBounty;
                }

                if (obj1[j].word === needle){
                    sum += parseFloat(obj1[j].bounty);
                    if(paymail.length === 0){
                        paymail = obj1[j].paymail;
                    } else {
                        paymail += "," + obj1[j].paymail;
                    }
                    status = obj1[j].status;
                    voteStart = obj1[j].voteStart;
                    id = obj1[j].id;
                    
                    winner = obj1[j].winner;
                    // if(winner.length === 0){
                    //     winner = obj1[j].winner;
                    // } else {
                    //     winner += winner.indexOf(obj1[j]) === -1 ? "," + obj1[j].winner : winner += "";
                    // } //jjd 4-17-2022
                    //winner = obj1[j].winner;
                    
                    var index = obj1.findIndex(function(item, i){
                        return item.word === needle;
                        });
                    
                    var d = obj1.splice(index,1);
                    j -= 1;
                }
            }
          
            var aniArgs = {};
            aniArgs.word = needle;
            aniArgs.bounty = sum;
            aniArgs.paymail = paymail;
            aniArgs.status = status;
            aniArgs.voteStart = voteStart;
            aniArgs.winner = winner;
            aniArgs.id = id;
            
            if(sum > 0){
                //console.log("jsonObj:", aniArgs);
            objTotal.push(aniArgs);
            }

            //console.log(obj1);
            
            //console.log("objTotal:",objTotal);
        }

        return objTotal;
    }


    // aggregateWBdata = (snapshotBounty) => {
    //     var objTotal = [];
    //     var obj = [];
    //     var docData = {
    //         word : "",
    //         bounty : 0,
    //         paymail : "",
    //         status : "",
    //         voteStart : "",
    //         createDatetime : "",
    //         paid : "",
    //         submitterEmail : "",
    //         txId : "",
    //         winner : "",
    //         wordExists : "",
    //         moneyButtonId : "",
    //         id : ""
    //     }
    //     for(var index = 0; index < snapshotBounty.size; index++){
            
    //         let doc = snapshotBounty.docs[index];
    //         docData.word = doc.data().word;
    //         docData.bounty = doc.data().bounty;
    //         docData.paymail = doc.data().paymail;
    //         docData.status = doc.data().status;
    //         docData.voteStart = doc.data().voteStart;
    //         docData.createDatetime = doc.data().createDatetime;
    //         docData.paid = doc.data().paid;
    //         docData.submitterEmail = doc.data().submitterEmail;
    //         docData.txId = doc.data().txId;
    //         docData.winner = doc.data().winner;
    //         docData.wordExists = doc.data().wordExists;
    //         docData.moneyButtonId = doc.data().moneyButtonId;
    //         docData.id = snapshotBounty.docs[index].id;

    //         obj.push(docData);
    //     }
    //     var obj1 = JSON.parse(JSON.stringify(obj));
    //     var needle = '';
    //     var sum = 0;
    //     var paymail = "";
    //     var status = "";
    //     var voteStart = "";
    //     var winner = "";
        
    //     for(var i=0; i < obj.length; i++){
    //         paymail = "";
    //         winner = "";
    //         needle = obj[i].word;
    //         sum = 0;
    //         for (var j = 0; j < obj1.length; j++){
    //             if (obj1[j].word === needle){
    //                 sum += parseFloat(obj1[j].bounty);
    //                 if(paymail.length === 0){
    //                     paymail = obj1[j].paymail;
    //                 } else {
    //                     paymail += "," + obj1[j].paymail;
    //                 }
    //                 status = obj1[j].status;
    //                 voteStart = obj1[j].voteStart;
                    
    //                 if(winner.length === 0){
    //                     winner = obj1[j].winner;
    //                 } else {
    //                     winner += "," + obj1[j].winner;
    //                 }
    //                 //winner = obj1[j].winner;
                    
    //                 var index = obj1.findIndex(function(item, i){
    //                     return item.word === needle;
    //                     });
                    
    //                 var d = obj1.splice(index,1);
    //                 j -= 1;
    //             }
    //         }
          
    //         var aniArgs = {};
    //         aniArgs.word = needle;
    //         aniArgs.bounty = sum;
    //         aniArgs.paymail = paymail;
    //         aniArgs.status = status;
    //         aniArgs.voteStart = voteStart;
    //         aniArgs.winner = winner;
    //         aniArgs.id = obj1.id
            
    //         if(sum > 0){
    //             console.log("jsonObj:", aniArgs);
    //         objTotal.push(aniArgs);
    //         }

    //         console.log(obj1);
            
    //         console.log("objTotal:",objTotal);
    //     }

    //     return objTotal;
    // }
}

export default WBCompletedTableRows;
