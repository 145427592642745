import firebase from '../../Firebase';

var moment = require('moment');

const updateWBStatus = async(word, status) => {

    var db = firebase.firestore();

    var docRef = db.collection("word-bounty")
        .where("word", "==", word)
        .get()
            .then(function(querySnapshot){
                querySnapshot.forEach(function(doc) {
                    let wbRef = db.collection("word-bounty").doc(doc.id)
                        .update({
                            status: status
                        })
                });
            }); 
}

const updateWBVoteStart = async(word,voteStart) => {
    var db = firebase.firestore();

    var docRef = db.collection("word-bounty")
        .where("word", "==", word)
        .get()
        .then(docs => {
            docs.forEach(async doc => {
                let wbRef = await db.collection("word-bounty").doc(doc.id)
                    .update({
                        voteStart: voteStart
                    })
            });
        })
        .catch(error => {
            this.errorMessage = 'Error - ' + error.message
        }); 
}

export { updateWBStatus, updateWBVoteStart};