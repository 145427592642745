import React from 'react';

class BaseComponent extends React.Component {

    constructor(props) {
        super(props);
        sessionStorage.setItem("previousUrl",this.props.location.pathname);
        this.defaultProp = {
          userID: ""
        };
      }
  
    componentWillMount(){
      document.body.style.backgroundImage = "url('')";
    }

    async componentDidMount(word) {
        //document.body.style.backgroundImage = "url('')";
        
        let wb = document.getElementById("word-bounty");
        wb.style.display = "inline";

    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log(error, info);  
      }
  }
  
  export default BaseComponent;