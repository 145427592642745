import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import React, { useState, useCallback } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import config from '../config';
import '../components/mySLictionary.css';
import './WordStats.css';


const SEEK_WORDS_URL = `${config.url.wordService}/revenue/seeks/100`;
const TOP_WORDSMITH_URL = `${config.url.userService}/wordsmiths/top/10`;

const gridStyle = { marginTop: 10, marginLeft: 50, minHeight: 300, minWidth: 500 }

const seekWordsColumns = [
    { name: 'word', header: 'Word', minWidth: 50, defaultFlex: 2 },
    { name: 'createDatetime', header: 'Create Datetime', minWidth: 50, defaultFlex: 2 }
];

const wordSmithColumns = [
    { name: 'order', header: '#', minWidth: 10, defaultFlex: 2 },
    { name: 'user', header: 'Wordsmith', minWidth: 50, defaultFlex: 2 }
];


const loadSeekWordsData = () => {
    return fetch(
      SEEK_WORDS_URL
    ).then(response => response.json());
  };

const loadTopWordsmithData = () => {
    return fetch(
        TOP_WORDSMITH_URL
    ).then(response => response.json());
};


const WordStats = () => {
    const seekWordsDataSource = useCallback(loadSeekWordsData, []);
    const topWordSmithSource = useCallback(loadTopWordsmithData,[]);
    const [columns] = useState(seekWordsColumns);
    const [topWordSmithColumns] = useState(wordSmithColumns);
    document.body.style.backgroundImage = "url('')";
  return (
    <div >
        <table>
            <tr>
                <td>
                    <div style={{fontSize:"18pt",fontWeight:"bold", marginLeft: "50px"}}>Last 100 Searched Words</div>
                    <ReactDataGrid
                        style={gridStyle}
                        idProperty="id"
                        columns={seekWordsColumns}
                        dataSource={seekWordsDataSource}
                        theme={"default-dark"}
                    />
                </td>
                <td style={{width:"25px"}}>&nbsp;</td>
                <td>
                    <div style={{fontSize:"18pt",fontWeight:"bold",width:"250px",marginLeft:"50px"}}>Top Wordsmiths</div>
                    <ReactDataGrid
                        style={gridStyle}
                        idProperty="topWordSmithId"
                        columns={topWordSmithColumns}
                        dataSource={topWordSmithSource}
                        theme={"default-dark"}
                    />
                </td>
            </tr>
        </table>
    </div>
  )
}

export default () => <WordStats />

