import firebase from '../../Firebase';

const gimmeDoc = async (word) => {
    let db = firebase.firestore();

    let wordLower = word;      
    var docRef = db.collection("words").doc(wordLower);

    const doc = docRef.get();

    return doc;

}

export {gimmeDoc};