import React from 'react';
import BaseComponent from './Common/BaseComponent';
import '../components/mySLictionary.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TokenSlider from './Common/TokenSlider';
import Slideshow from './Common/StatsSlideshow';
import {Spinner} from 'spin.js';
import 'spin.js/spin.css';
import { getVoteEarnings } from '../mods/slictionary-vote-mod';
import {getWordsByUser} from '../components/Common/Words';
import Wallet from "../components/Common/Wallet";


const config = require("../config");
const axios = require("axios");

// var exchangeRate = 0;
// var bsvBalance = 0;
//var satoshiBalance = sessionStorage["satoshiBalance"];


var voteCount = sessionStorage["voteCount"];
var voteEarnings;
var wordAnnualEarnings;

//var transferToPrivateKey;

//var satoshiBalance;

var opts = {
    lines: 13, // The number of lines to draw
    length: 38, // The length of each line
    width: 17, // The line thickness
    radius: 45, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#ffffff', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute', // Element positioning
  };
  

class mySLictionary extends BaseComponent {
    

    constructor(props) {
        super(props);
        
        this.state = {
            words: [],
            searchResults: [],
            wordSmiths: [],
            show: false,
            tokens: [],
            wordsByUser: []
        }
        //this.Tabs = this.Tabs.bind(this);
        // this.TabList = this.TabList.bind(this);
        // this.Tab = this.Tab.bind(this);
        // this.TabPanel = this.TabPanel.bind(this);

        this.cool = this.cool.bind(this);

    }

    cool = async() => {
        
        
        var spinner = new Spinner(opts);
  
        voteEarnings = await getVoteEarnings(localStorage.getItem("senderPaymail"));
        let text = await getWordsByUser(localStorage.getItem("senderPaymail"));

        let wordsByUser = text;

        if (text.length === 0) {
          wordsByUser = wordsByUser.concat("You have not defined any words.")
        }
        
        this.setState({words:wordsByUser});
        
    }

    async componentWillMount(){
        document.body.style.backgroundImage = "url('')";
        wordAnnualEarnings = await axios.get(`${config.url.voteService}/token/word-annual-earnings-by-token/${localStorage.getItem("walletAddress")}`); 

        this.cool();
    }

    async componentDidMount(word) {
        //spinner.spin(document.getElementById("smithedWords"));
    } 
  
  handleShow = () => {
    this.setState({show:!this.state.show});
    return false;
  }

  handleClose = () => {
    this.setState({show:!this.state.show});
  }


    callbackFunction = (childData) => {
  
    }
   
    receiveClick(event){
        let walletAddress = localStorage.getItem("walletAddress");
        navigator.clipboard.writeText(walletAddress);

        alert(`Your wallet address ${walletAddress} has been copied to the clipboard`);
    }

    render() { 
      
      return (
        <div className="content" style={{color:"white", textAlign:"left"}}>
        <Container style={{"width":"100%"}}>
            <Row>
                <Col style={{border:"2px solid white",borderRadius: "10px",height:"200px", margin:"auto"}}>
                    <div style={{fontFamily:"Cambria", fontSize: "20pt"}}>Stats</div>
                    <div   id="smithedWords">
                        <Slideshow voteCount={voteCount} voteEarnings={voteEarnings}/>
                    </div>
                </Col>
                <Col style={{border:"2px solid white","borderRadius": "10px","margin":"10px"}}>

                {<Wallet address={localStorage.getItem("walletAddress")} spinnerOpts={opts}/>}

                </Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row>
                                                                
            <Col>                                                    
        
            <Tabs>
                <TabList>
                    <Tab> MY SMITHED DEFINITIONS </Tab>
                    <Tab> MY OWNED <b>SLICDEF</b> TOKENS </Tab>
                    {/* <Tab> Profile </Tab> */}
                </TabList>
                <TabPanel>
                <h4>Words you have defined:</h4>
                <Container style={{width:1000}}>

                        <p  style={{marginLeft:"60px"}}>
                        <ul>
                            {this.state.words.map((word,i) =>
                                <li key={i}><a href={"/testit/" + word + "/@"}>{word}</a></li>
                            )}
                        </ul>
                        
                    </p>
                    </Container>
                </TabPanel>
                <TabPanel>
                    <h3>NFTs you own:</h3>
                    <Container style={{width:1000}} id="container">
                        <Row>
                            <Col>
                                <div id="demo" className="carousel slide" data-ride="carousel">
                                    <div style={{marginTop:"15px"}}>
                                    {<TokenSlider parentCallback = {this.callbackFunction} word="bitcoin" wordAnnualEarnings={wordAnnualEarnings}/>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </TabPanel>
            </Tabs>
        </Col>
        </Row>
        </Container>
          <Container style={{width:1000}}>
            <Row>
                <Col className="col col-12">
                    
                </Col>
            </Row>
          </Container>  
          <Modal show={this.state.show} onHide={this.handleClose} >
                <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                    <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Send BSV</Modal.Title>
                </Modal.Header> 
                <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modalTwetch" style={{color:"white",backgroundColor:"#202020"}}>
                    
                        <div>
                            
                            <div>
                            <form>
                                <div><span><input type="text" id="txtToAddress" required maxlength="100" style={{borderRadius: "0px",width:"100%", height:"50px", padding:"5px"}} placeholder="Enter recipient address"></input></span></div>
                                <div><span>&nbsp;</span></div>
                                <div><span><input type="text" id="txtUSDamount" required pattern="[0-9\.]+" maxlength="100" style={{borderRadius: "0px",width:"100%",height:"50px", padding:"5px"}} placeholder="Enter amount to send in USD"></input></span></div>
                                <div><span>&nbsp;</span></div>
                                <div><span>
                                    <button id="btnSend" type="button" onClick={this.sendClick} className="btn btn-primary" 
                                        style={{width:"100%",height:"50px", backgroundColor:"black", 
                                        borderColor:"white"}}>
                                        <i hidden id="iSendSpinner" className="fa fa-spinner fa-spin"></i>
                                        &nbsp;Send
                                    </button>
                                    </span>
                                </div>
                            </form>
                            </div>

                        </div>

                    
                    </div>

                </Modal.Body>
        </Modal>

        </div>
      );
    }
  
}

export default mySLictionary;