import React from 'react';
import BaseComponent from './Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HowItWorks.css';


class HowItWorks extends BaseComponent {

  constructor(props) {
    super(props);
    
  }

  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"white"}}>
          <h2 className="howItWorksTitle">How It Works</h2>

          <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/2X1SytUUd5Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div  style={{ 
                padding:'5px',
                borderRadius:'15px',
                border:'0.5px solid black',
                width:'800px',
                background:'black',
                color:'white'}}>
            <h3><b>LEARN</b></h3>
            <p className="howItWorksText">
              <ul>
                <li>Search for words in the dictionary.</li>
            <li className="howItWorksText">Definitions are RANKED to save you time.</li>
            <li className="howItWorksText">PICTURES &amp; SOUNDS &amp; VIDEOS are positioned first to save you time, because learning uses multiples senses
Multiple definitions so you can choose</li>
</ul>
</p>

            <h3><b>EARN</b></h3>
            <p className="howItWorksText">All words are "smithed" by SLictionarian WordSmiths--the same way words formed and adapted in the past--COMMUNITY</p>
            <p className="howItWorksText">Ordinary dictionaries have ONE definition; SLictionary’s words have UNBOUNDED definitions for each word.  The community decides what is the proper number.
WordSmiths make 70% of all revenues-- they earn when Searchers VOTE on best definitions which “turn on their lightbulbs” over top of their head!</p>
            <p className="howItWorksText">Lightbulb icons are the VOTES, pressed by satisfied searchers to reward WordSmiths.</p>
            <p className="howItWorksText">Anyone can be a WordSmith, simply hit the "Define a Word" button, or "Earn Money" button after a search yielding no search return.</p>

            <p>&nbsp;</p>
            <h3><b>BURN</b></h3>
            <p className="howItWorksText">We're burning all the inefficient, lazily-concieved dictionary skeuomorphic saddlebags.  How?  Communal VOTES (lightbulb-icons) by Learners return human communication art to ORGANIC ALWAYS-MOVING SOCIETAL Self-Learning-- the SL in SLictionary-- for Learners' benefit.  SLictionary’s goal: people learning as QUICK as possible for as LONG as possible without coming back.  We bespeak for “Method of Loci” but wish to even improve on that memorization tactic.  Here’s some examples of how we are different from reference materials you’ve used in the less-efficient past:</p>
            <p className="howItWorksText">Alphabetic pronounciations are gone-- there's no instance where audio pronounciation isn't better.</p>
            <p className="howItWorksText">Voting dynamically changes SLictionary's algos to improve performance for the NEXT word lover.</p>
            <p className="howItWorksText">Eventually the most popular PROPERTIES (picture, video, written definition, sentence example) will be displayed more emphatically to increase learning-speed for vocabulary enthusiasts.</p>
            <p className="howItWorksText">We emphasize pictures bc they are cheap online; whereas in books they were expensive thus excluded.</p>
            <p className="howItWorksText">We'll allow you to burn your bound dictionaries when we're done filling out our community of words-we will be a much-improved SLICKER dictionary.</p>
          
            <p>&nbsp;</p>
            <h3><b>BEST PRACTICES</b></h3>
            <p className="howItWorksText">Look up the word using multiple sources-- Wikipedia, Dictionary.com, Merriam-Websters, Oxford, Cambridge, all the colleges, then write your definition in YOUR OWN WORDS as if explaining the meaning to a child.  Copying/pasting will get your definition in the trash heap eventually.  Words are communal and SLictionary is mean to be written by the people in our own words.  We PREFER less formality in favor of getting your meaning across.  SLictionary will favor efficiency not formality in the end.  We don’t say so, SLictionarians do with their light-bulbous votes.</p>
            <p className="howItWorksText">Think the picture of the word in your head, and then go look for it or draw it.  (later versions will allow making a video)</p>
            <p className="howItWorksText">MacOS: you can use native QuickTime to make recordings of your pronounciation.  It’s tricky but once you do it once you’ll have a routine.  In the next version we’ll simply add a mic/recording button for computers with native mics to make this easier.</p>
            <p className="howItWorksText">Feel free to take a screenshot of your definition (& word) and throw it up on Twetch.app or BAEmail it to your friends.  YOU make 70% of revenues, so marketing SLictionary is in your own best long-term interest.  If you have friends who like words and word games, SLictionary is for them.  At scale, SLictionary is like a little money engine with the world’s greatest ever-lasting energy-supply: WordSmiths invest proof-of-work in the beginning, but harvest revenues for a long time thereafter without doing much.  Time is best invested making the most helpful definition possible, and then promoting the site so you get paid for your wisdom.</p>
            <p className="howItWorksText">Don’t forget to add a picture and a verbal pronounciation.  Incomplete definitions will likely not recieve lightbulb-votes and lower themselves in the rankings.  WordSmiths will earn more if they have complete definitions-- pleasing to learners.</p>

      </div>
      </div>
    );
  }
}

export default HowItWorks;