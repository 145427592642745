import React from 'react';
import BaseComponent from '../Common/BaseComponent';
import $ from 'jquery';

var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;

class modalTwetch extends BaseComponent{

    
    constructor(props) {
        super(props);

        let arr = this.props.location.pathname.split("/");

        this.state = {
            show: false,
            setShow: false,
            amount: arr[2]
        }

        this.myOnPaymentCallback = this.myOnPaymentCallback.bind(this);

        
      }

      async myOnPaymentCallback(payment){
        window.parent.document.trigger('completed');
        //body.style.backgroundColor = "red";
      }

      componentWillMount(){
        document.body.style.backgroundImage = "url('')";
      }

    async componentDidMount(word) {
        //document.body.style.backgroundImage = "url('')";
        document.getElementById("footer").style.display = "none";
        //document.getElementById("footer").innerHTML = "";
        document.getElementById("topNav").style.display = "none";
               
    }

    //async myOnPaymentCallback(payment){
        // window.parent.postMessage("HELLO_PARENT", 'http://localhost:3000/modalTwetch');
        // console.log(parent.txid);
    //}
    render() {

        return(
            
            <div id="modalContent" style={{color:"white",backgroundColor:"#202020"}}>
                <div>Create a Word!</div>
                <div>This fee includes:</div>
                <ul style={{marginLeft:"25px"}}>
                    <li>1 cent to create a word</li>
                    <li>3 cents to post it to Twetch</li>
                </ul>
                
                <MoneyButton
                        to="selflearningdictionary@MoneyButton.com"
                        amount={this.state.amount}
                        currency="USD"
                        onPayment={this.myOnPaymentCallback.bind(payment)}
                        label="Create Word"
                        onLoad={this.onMoneyButtonLoad}
                        buttonId=""
                        buttonData="{app: SLictionary}"
                        opReturn="SLic"
                />
            </div>
            
           
        );
    }
}

export default modalTwetch;
