//import firebase from '../../Firebase';
import axios from 'axios';
const config = require('../../config');

const getWordBaronPaymail = async(componentId) => {
    let wordBaronPaymail = await axios.get(`${config.url.wordBountyService}/wordBaronPayMail/${componentId}`);
    
    return wordBaronPaymail;    
}

export { getWordBaronPaymail };