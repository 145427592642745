import React, { Component } from 'react';
import firebase from '../Firebase';
import { setWordDefinition } from '../mods/slictionary-word-mod';
import { getUUID } from '../mods/slictionary-util-mod';
import { addToActiveBounty } from '../mods/slictionary-word-bounty-mod';
import 'bootstrap/dist/css/bootstrap.min.css';
import generatePushID from './Common/PushId.js';
import Button from 'react-bootstrap/Button';
import { getWordId, logWord, wordExists } from './Common/Words';
import { updateWBVoteStart } from '../services/firebase/database';
import MicRecorder from 'mic-recorder-to-mp3';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import recorderIcon from '../assets/mic-record-audio.png';
import "./CreateWord.css";
import "./Blink.css";

import {Mode} from '../enums/AddWordEnum.ts';
import {getRawTx, getUTXOs} from '../mods/slictionary-bsv-mod';
import {postImage} from '../mods/slictionary-twetch-mod';
import Modal from 'react-bootstrap/Modal';
import { Checkbox } from '@material-ui/core';
import { twetchFromAddress, twetchWorkSpaceId, twetchClientIdentifier } from '../constants/twetch-constants';
import { getTokens, createToken } from '../mods/slictionary-token-mod';
import { tokenSatAmount } from '../constants/token-constants';
import { getWalletInfo } from '../mods/slictionary-user-mod';
import { addErrorToLog } from '../mods/slictionary-error-log-mod';

const config = require("../config");

var moment = require('moment');
var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;
var imgDownloadUrl = "";
var audioDownloadUrl = "";

var upload;

let bWordExists = true;
const Mp3Recorder = new MicRecorder({ bitRate: 256 });
const fs = require('fs');
const axios = require("axios");

const transfer = {
    to: null,
    amount: null,
    currency: 'USD'
  }

class CreateWord extends Component {
    
    constructor(props) {

        super(props);

        let arr = this.props.location.pathname.split("/");
        this.state = {
            word: arr[2] === "@" ? " " : arr[2],
            defId: arr[3],
            txid: arr[4],
            senderPaymail: localStorage.getItem("senderPaymail"),
            selectedImageFile: null,
            selectedAudioFile: null,
            definition:"",
            usage:"",
            pronunciation:"",
            docId:"",
            imgDownloadUrl: "",
            partOfSpeech: "",
            childproof: "",
            buttonJSX:null,
            isRecording: false,
            blobURL: '',
            isBlocked: false,
            profileImg:'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            mode: "",//arr[3] === "@" ? Mode.AddWord : Mode.AddVersion, //if defId = @, it's an Add New Word
            docCurrent:null,
            show: false,
            setShow: false,
            amount: "0.0096",
            toPaymail:"selflearningdictionary@MoneyButton.com",
            currency: "USD",
            twetchChecked: false,
            canPostToTwetch: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.selectedImageHandler = this.selectedImageHandler.bind(this);
        this.selectedAudioHandler = this.selectedAudioHandler.bind(this);
        this.onfocusout = this.onfocusout.bind(this);
        this.onAddWordClick = this.onAddWordClick.bind(this);
        this.onKeyDownDescription = this.onKeyDownDescription.bind(this);
        this.addWord = this.addWord.bind(this);
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.onRecorderClick = this.onRecorderClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.loadFileUrl = this.loadFileUrl.bind(this);
        this.setPaymail = this.setPaymail.bind(this);
        this.setAmount = this.setAmount.bind(this);
        this.setSelected = this.setSelected.bind(this);
    }
    setAmount = (value) => {
        this.setState({ amount: value })
      }

    setSelected = (value) => {
        this.setState({ twetchChecked: value });
        if(value === true){
            this.setAmount("0.015");
        } else {
            this.setAmount("0.0005");
        }
    }
    
    async componentWillMount(){ 
        const bitcoinfiles = require('@matterpool/bitcoinfiles-js');
        document.body.style.backgroundImage = "url('')";
        let rawTx = await axios.get(`${config.url.bsvService}/rawTx/17XpmG3keK18LF8Bzpf7MhTEFSiK9QE2Xt/1LxsJshpecf1VCH4ZKFXpekAo4NagwLCd2/25368/0`);
    }
    
    async componentDidMount(word) {
        
        const element = document.getElementById("input"); 
        element.addEventListener('change', async(e) => {
            try{
                if(this.state.canPostToTwetch){
                    var fromAddress = twetchFromAddress;
                    var workSpaceId = twetchWorkSpaceId;
                    const bitcoinfiles = require('@matterpool/bitcoinfiles-js');
                    var rawTx = "";

                    axios.get(`${config.url.bsvService}/rawTx/${fromAddress}/${this.state.payment_address}/${this.state.payment_satoshis}/0`)
                    .then(function (response) {
                        // handle success
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });

                    const payment = await bitcoinfiles.pay(rawTx);
                    const txid = payment[0]// txid of the bitcoin file  

                    const Twetch = require('@twetch/sdk');
                    var options = {clientIdentifier: twetchClientIdentifier};
                    const twetch = new Twetch(options);

                    await postImage(twetch,'','','',txid);
                } 
            }catch (error) {
                console.log(error);
            }
            
        });

        const doc = await this.getDoc(this.state.word);
        bWordExists = await wordExists(this.state.word);
        if(bWordExists){
            this.setState({mode:Mode.AddVersion});
        } else {
            this.setState({mode:Mode.AddWord});
        }     
        this.setState({docCurrent: doc});

        document.body.style.backgroundImage = "url('')";

        if (this.state.word === " "){
            this.setState({word:""});
        }    

        if(this.state.txid !== "@" && this.state.mode === Mode.AddWord){
            this.setState({buttonJSX: <Button 
                
                className="btn btn-primary btn-block"
                onClick={this.onAddWordClick} style={{width:"150px"}}>
                    FREE Add Word
                </Button>
            });
        } else {
        }

        navigator.mediaDevices.getUserMedia({ audio: true },
            () => {
              this.setState({ isBlocked: false });
            },
            () => {
              this.setState({ isBlocked: true })
            },
          );
    }

    onAddWordClick = async(event) =>{
        this.addWord(this.state.senderPaymail, this.state.txid, "0.00");
    }

    addWord = async(senderPaymail, txid, amount) =>{
        let db = firebase.firestore();
        var docRef = db.collection("words").doc(this.state.word);
        this.setState({defId:  generatePushID()});

        docRef.get().then(function(doc) {
            if (bWordExists) {

            } else {
                
            }
        }).catch(function(error) {
            addErrorToLog(JSON.stringify(error),"addWord");
            throw error;
        });

        if(audioDownloadUrl.length === 0){

        }

        var defId = "def" + generatePushID();
        var imageId = "img" + generatePushID();
        var pronunciationId = "aud" + generatePushID();
        var partOfSpeechId = "prt" +generatePushID();
        var componentIds = [defId, imageId, pronunciationId, partOfSpeechId];

        var docData = {
            firstThree:this.state.word.substring(0,3),
            first:this.state.word.substring(0,1),
            kidFriendly: !document.getElementById('childproof').checked,
            isSlang: document.getElementById('slang').checked,
            isSAT: false,
            createDate: moment().format(),

            definitions:[
                {
                id: this.state.defId,
                partOfSpeech: [
                    {
                        createDate: moment().format(),
                        id:partOfSpeechId
                        ,author: senderPaymail
                        ,value: this.state.partOfSpeech
                    }
                ]
                ,defVersions:[
                    {
                        createDate: moment().format(),
                        id: defId,
                        author: senderPaymail
                        ,definition: this.state.definition
                        ,usage: this.state.usage
                    }
                ]
                ,images:[
                    {
                        createDate: moment().format(),
                        id: imageId,
                        author:senderPaymail
                        ,imgUrl: imgDownloadUrl
                    }
                ]
                ,pronunciations:[
                    {
                        createDate: moment().format(),
                        id: pronunciationId,
                        author: senderPaymail
                        ,IPA: ""
                        ,phonetic: this.state.pronunciation
                        ,audioUrl: audioDownloadUrl
                    }
                ]
                ,videos:{
                    createDate: moment().format(),
                    id: generatePushID(),
                    author: senderPaymail
                    ,videoUrl: ""
                }
            }]
        };
        logWord(this.state.word,Mode.AddWord,docData);
        var definitionId =  getUUID();
        console.log(`definitionId: ${definitionId}`);
        var word = this.state.word;
        
        //add the word
        await db.collection("words").doc(this.state.word).set(docData)
        .then(function() {    
        }).catch(function(error) {
            //console.log(error);
            let errorResultsGeneral = addErrorToLog(`Error thrown in word creation: ${error.toString()}`,"addWord");
            throw error;
        });

        await setWordDefinition(this.state.word, definitionId, imgDownloadUrl, audioDownloadUrl,componentIds)
        .then( element => {})
        .catch(err => {
            addErrorToLog(JSON.stringify(err),"setWord");
            throw err;
        });        
        
        let results = await addToActiveBounty(this.state.word, definitionId)
        .then(function() {
        })
        .catch(err => {
            addErrorToLog(JSON.stringify(err),"setWordVersion");
        });

        let resultsWBVoteStart = await updateWBVoteStart(this.state.word,moment().format())
        .then(element => {})
        .catch (err =>{
            let errorResults = addErrorToLog(`Error thrown in updateWBVoteStart: ${err.toString()}`);
            throw err;
        })

        await db.collection("revenue").doc("revenueDoc").collection("words").add({
            userId: senderPaymail,
            datetime:  firebase.firestore.FieldValue.serverTimestamp(),
            word: this.state.word,
            txId: txid,
            type: "addWord",
            amount: Number(amount)
        }).then(function(docRef) {
        })
        .catch(function(error) {
            addErrorToLog(`Error thrown in addRevenueDoc: ${error.toString()}`,"addRevenueDoc")
            throw error;
        });

        window.location.assign("/testit/" + this.state.word + "/txid");
    }

    async updateDefinition(word,defId,author){
        
        let db = firebase.firestore();

        let docFormData = this.getFormData(author);

        let docRef = this.state.docCurrent;
        let json = docRef.data();

        for (var key in json.definitions) {
            var item = json.definitions[key];
                if(docFormData.definitions[0].defVersions[0].definition.length > 0){
                    let tmp = json.definitions[key].defVersions.concat(docFormData.definitions[0].defVersions);
                    json.definitions[key].defVersions = tmp;
                    //had to do this because Firestore doesn't allow directly nested arrays. In order to 
                    //put one array inside another, the inner array has to be wrapped in an object.
                }
                if(docFormData.definitions[0].images[0].imgUrl.length > 0){
                    let tmp = json.definitions[key].images.concat(docFormData.definitions[0].images);
                    json.definitions[key].images = tmp;
                }
                if(docFormData.definitions[0].partOfSpeech[0].value.length > 0){
                    let tmp = json.definitions[key].partOfSpeech.concat(docFormData.definitions[0].partOfSpeech);
                    json.definitions[key].partOfSpeech = tmp;
                }
                if(docFormData.definitions[0].pronunciations[0].audioUrl.length > 0){
                    let tmp = json.definitions[key].pronunciations.concat(docFormData.definitions[0].pronunciations);
                    json.definitions[key].pronunciations = tmp;
                }
        }
        db.collection("words").doc(this.state.word).set(json).then(function() {
        }).catch(err => {
            addErrorToLog(`Error in updateDefinition. ${JSON.stringify(err)}`,"setWordVersion");
            throw err;
        });
    }

    getFormData(authorName,componentIdJson){
        return {
            definitions:[
            {
                partOfSpeech: [
                    {
                        createDate: moment().format(),
                        id:componentIdJson.partOfSpeechId
                        ,author: authorName
                        ,value: this.state.partOfSpeech
                    }
                ]
                ,defVersions:[
                        {
                            createDate: moment().format(),
                            id: componentIdJson.defId,
                            author: authorName
                            ,definition: this.state.definition
                            ,usage: this.state.usage
                        }
                ]
                ,images:[
                    {
                        createDate: moment().format(),
                        id: componentIdJson.imageId,
                        author:authorName
                        ,imgUrl: imgDownloadUrl
                    }
                ]
                ,pronunciations:[
                    {
                        createDate: moment().format(),
                        id: componentIdJson.pronunciationId
                        ,author: authorName
                        ,IPA: ""
                        ,phonetic: this.state.pronunciation
                        ,audioUrl: audioDownloadUrl
                    }
                ]
                ,videos:[{
                    createDate: moment().format(),
                    id: generatePushID(),
                    author: authorName
                    ,videoUrl: ""
                }]
            }]
        };
    }

    onfocusout = async(event) =>{
        let word = document.getElementById('word').value;
        if(word.length > 0){
            let ID = await getWordId(word);
           
            if (ID.length > 0){
                //word exists. Switch to AddVersion mode.
                this.setState({mode: Mode.AddVersion});
                this.setState({defId: ID});
                this.setState({word: word});
                const doc = await this.getDoc();
                this.setState({docCurrent: doc});
            } else {
                //TODO: Log that the word does not exist.
            }
        }
    }

    onKeyDownDescription = async(event) => {

    }

    handleChange(event){

        switch(event.target.id){
            case "word" :
                let word = event.target.value;
                this.setState({word: word.toLowerCase()});
                 break;
            case "definition" :
                this.setState({definition: event.target.value});
                break;
            case "usage" :
                this.setState({usage: event.target.value});
                break;
            case "pronunciation" :
                this.setState({pronunciation: event.target.value});
                break;
            case "selectedImageFile" :
                break;
            case "selectedAudioFile" :
                break;
            case "partOfSpeech":
                this.setState({partOfSpeech: event.target.value});
                break;
            default:
        }
    }

    uploadToStorage = async(imageURL) => {
        this.getFileBlob(imageURL, blob =>{
            firebase.storage().ref().put(blob).then(function(snapshot) {
            })
        });
    }
   
    getFileBlob = function (url, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function(e) {
          if (this.status === 200) {
            cb = this.response;
            // myBlob is now the blob that the object URL pointed to.
          }
        };
        xhr.send();
    }

    uploadFile = async (file,fileType) => {
        let storageRef = firebase.storage().ref();
        let lastIndexOf = file.name.lastIndexOf(".");
        let fileName = file.name.substring(0,lastIndexOf) + "_" + Math.random().toString().substring(2,8) + file.name.substring(lastIndexOf);
        var uploadTask = storageRef.child('words/' + fileName).put(file);

        uploadTask.on('state_changed', function(snapshot){
            
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progress = Math.round(progress);


        }, function(error) {
            // Handle unsuccessful uploads
        }, function() {
            
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                if(fileType === "image"){
                    imgDownloadUrl = downloadURL;
                }else{
                    audioDownloadUrl = downloadURL;
                }
            });
        });

    }

    selectedImageHandler = event =>{
        this.setState({selectedImageFile: event.target.files[0]});
        this.uploadFile(event.target.files[0],"image");
        
    }
    
    selectedAudioHandler = event =>{
        this.setState({selectedAudioFile: event.target.files[0]});
        this.uploadFile(event.target.files[0],"audio");
    }

    async getDoc(word){
        let db = firebase.firestore();

        let wordLower = this.state.word;      
        var docRef = db.collection("words").doc(wordLower);
  
        const doc = docRef.get();

        return doc;

    }

    myOnPaymentCallback = async(payment) => {
        this.setState({word:this.state.word.trim()});

        localStorage.setItem('senderPaymail',payment.senderPaymail);
 
        if (this.state.mode === Mode.AddWord){
           
            this.addWord(payment.senderPaymail, payment.txid, payment.amount);

        } else if (this.state.mode === Mode.AddVersion){
            this.myOnPaymentCallback_AddVersion(payment);
        }
        
    }

    myOnPaymentCallback_AddVersion = async(payment) => {
        let db = firebase.firestore();
        let author = payment.senderPaymail;

        let defId = "def" + generatePushID();
        let imageId = "img" + generatePushID();
        let pronunciationId = "aud" + generatePushID();
        let partOfSpeechId = "prt" + generatePushID();
        let componentIdJson = {"defId":defId, "imageId" : imageId, "pronunciationId" : pronunciationId, "partOfSpeechId" : partOfSpeechId};
        let componentIds = [defId, imageId, pronunciationId, partOfSpeechId];
        let definitionId =  getUUID();

        let docFormData = this.getFormData(author, componentIdJson);
        let docRef = this.state.docCurrent;
        let json = docRef.data();

        for (var key in json.definitions) {
            var item = json.definitions[key];
            let tmp;
            tmp = json.definitions[key].defVersions.concat(docFormData.definitions[0].defVersions);
            json.definitions[key].defVersions = tmp;
            //had to do this because Firestore doesn't allow directly nested arrays. In order to 
            //put one array inside another, the inner array has to be wrapped in an object.
            tmp = json.definitions[key].images.concat(docFormData.definitions[0].images);
            json.definitions[key].images = tmp;
            tmp = json.definitions[key].partOfSpeech.concat(docFormData.definitions[0].partOfSpeech);
            json.definitions[key].partOfSpeech = tmp;
            tmp = json.definitions[key].pronunciations.concat(docFormData.definitions[0].pronunciations);
            json.definitions[key].pronunciations = tmp;
        }

        logWord(this.state.word,Mode.AddVersion,json);
        await db.collection("words").doc(this.state.word).set(json).then(function() {
        })
        .catch(err => {
            addErrorToLog(JSON.stringify(err),"addWordVersion");
            throw err;
        });

        await setWordDefinition(this.state.word, definitionId, imgDownloadUrl, audioDownloadUrl, componentIds)
        .then(function() {
        })
        .catch(err => {
            addErrorToLog(JSON.stringify(err),"setWordVersion");
            throw err;
        });

        let results = await addToActiveBounty(this.state.word, definitionId)
        .then(function() {
        })
        .catch(err => {
            addErrorToLog(JSON.stringify(err),"addVersionToActiveBounty");
            throw err;
        });
        
        let transactions;
        
        db.collection("revenue").doc("revenueDoc").collection("words").add({
            userId: payment.senderPaymail,
            datetime:  firebase.firestore.FieldValue.serverTimestamp(),
            word: this.state.word,
            txId: payment.txid,
            type: "addVersion",
            amount: payment.amount
        }).then(function(docRef) {
        })
        .catch(function(error) {
            addErrorToLog(JSON.stringify(error),"addVersionToRevenueDoc");
            throw error;
        });
    

        window.location.assign("/testit/" + this.state.word + "/" + this.state.defId);
    }

    start = () => {
        if (this.state.isBlocked) {
          
        } else {
            document.querySelector('#playlist').innerHTML = "";

            Mp3Recorder
                .start()
                .then(() => {
                    this.setState({ isRecording: true });
                    }).catch(function(e){ 
                        
                        let d = document.getElementById("imgRecorder");
                        let txt = document.getElementById("txtRecorder");
                        d.className = "pointer";
                        txt.style.display = "none";

                        alert("Microphone not detected");
                    });
        }
    }

    stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                let storageRef = firebase.storage().ref();
                let filename = this.state.word + "-" + localStorage.getItem("senderPaymail") + "-" + moment().format() + ".mp3";
                var uploadTask = storageRef.child("audio/" + filename).put(blob);
                uploadTask.on('state_changed', function(snapshot){
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'

                        break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'

                        break;

                        default:
                    }
                }, function(error) {
                    // Handle unsuccessful uploads
                    addErrorToLog(JSON.stringify(error),"audio-stop");
                }, function() {
                    // Handle successful uploads on complete
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        audioDownloadUrl = downloadURL;
                        
                        const file = new File(buffer, 'music.mp3', {
                            type: blob.type,
                            lastModified: Date.now()
                        });
                        const player = new Audio(URL.createObjectURL(file));

                        player.controls = true;
                        
                        document.querySelector('#playlist').appendChild(player);
                    
                    });
                });
          
              }).catch((error) => {
                addErrorToLog(JSON.stringify(error),"audio-stop");
            });
    }

    imageHandler = async (e) => {
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2){
                this.setState({profileImg: reader.result})
            }
        }
        reader.readAsDataURL(e.target.files[0]);

        this.uploadFile(e.target.files[0],"image");

        var fromAddress = twetchFromAddress;
        var workSpaceId = twetchWorkSpaceId;
        const bitcoinfiles = require('@matterpool/bitcoinfiles-js');
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file',file);//,'file.png');
        upload = await bitcoinfiles.upload(formData, workSpaceId);
        this.setState({"payment_address" : upload.payment_address});
        this.setState({"payment_satoshis" : upload.payment_satoshis});
        let rawTx = await axios.get(`${config.url.bsvService}/rawTx/17XpmG3keK18LF8Bzpf7MhTEFSiK9QE2Xt/1LxsJshpecf1VCH4ZKFXpekAo4NagwLCd2/25368/0`);
        payment = await bitcoinfiles.pay(rawTx);
        const txid = payment.result[0].txid // txid of the bitcoin file  
        const Twetch = require('@twetch/sdk');
        var options = {clientIdentifier: twetchClientIdentifier};
        const twetch = new Twetch(options);

        await postImage(twetch,'','','',txid);
                
    }

    postToTwetch = async() => {
        try{
            var fromAddress = twetchFromAddress;
            var workSpaceId = twetchWorkSpaceId;
            const bitcoinfiles = require('@matterpool/bitcoinfiles-js');

            const file = document.getElementById('input').files[0];//e.target.files[0];
            const formData = new FormData();
            formData.append('file',file,'file.png');
            upload = await bitcoinfiles.upload(formData, workSpaceId);

            const rawTx = await getRawTx(fromAddress, upload.payment_address,upload.payment_satoshis,'');
            const payment = await bitcoinfiles.pay(rawTx);
            const txid = payment.result[0].txid // txid of the bitcoin file  

            const Twetch = require('@twetch/sdk');
            var options = {clientIdentifier: twetchClientIdentifier};
            const twetch = new Twetch(options);

            await postImage(twetch,'','','',txid);
        } catch(error){
            console.log(error);
        }
    }

    onRecorderClick = (e) =>{
        let d = document.getElementById("imgRecorder");
        let txt = document.getElementById("txtRecorder");
        if(d.className === "blink-image pointer"){
            //Stop recording-
            this.stop();

            this.setBlink(d, txt, false);
        } else {
            //Start recording
            this.start();   
            this.setBlink(d, txt, true);
        }
    }

    setBlink = (doc, txt, doesBlink) => {
        if(doesBlink){
            doc.className = "blink-image pointer";
            txt.style.display = "block";
        } else {
            doc.className = "pointer";
            txt.style.display = "none";
        }

        return false;
    }
  
    handleShow = () => {
        this.setState({show:!this.state.show});
        this.loadFileUrl();
        return false;
    }

    handleClose = () => {
        this.setState({show:!this.state.show});
    }

    loadFileUrl = async () => {
        const url = "http://localhost:3000/modalTwetch/0.04";
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'blob';
        request.onload = function() {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload =  function(e){
                console.log('DataURL:', e.target.result);
            };
        };
        request.send();
    }

    setPaymail = (paymail) => {
        transfer.to = paymail;
      }
  
    render() {
        const amount = this.state.amount;
        const currency = this.state.currency;
        const toPaymail = this.state.toPaymail;

    return (

      <div id="parentContainer" className="content" style={{color:"white"}}>
            <form style={{width:"75%"}}>
                
                <h3>
                    <span style={{color:"yellow"}}>{config.app.verbiage.smithingPage.addAword}.&nbsp;</span>
                    <span style={{color:"lightgreen"}}>{config.app.verbiage.smithingPage.beAwordsmith}.</span>
                     </h3>

                <div className="form-group" style={{width:"50%"}}>
                    <label>{config.app.verbiage.smithingPage.word}</label><label style={{color:"red"}}>*</label>
                    
                    <table style={{width:"200%",border:"0px"}}>
                        <tr style={{height:"50px"}}>
                            <td style={{width:"40%", verticalAlign:"top"}}>
                                <input type="text" id="word" value={this.state.word}  
                                dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                                onBlur={this.onfocusout}
                                onChange={this.handleChange} 
                                className="form-control"
                                required/>
                                <div className="valid-feedback">Valid.</div>
                                <div className="invalid-feedback">Please fill out this field.</div>
                            </td>

                            <td style={{paddingLeft:"20px", verticalAlign:"top"}}>
                                <span style={{width:"100px"}}>
                                    <img id="imgRecorder" className="pointer" 
                                        style={{width:"60px",display:"inline", marginTop:"-12px"}} 
                                        src={recorderIcon}
                                        title="Record Your Pronunciation"
                                        onClick={this.onRecorderClick} />
                                    
                                </span>

                                <div id="txtRecorder" style={{display:"none",fontSize:"10pt"}}>Click to stop recording</div>
                            </td>
                            <td style={{verticalAlign:"top"}}>
                                <div id="playlist" ></div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div className="form-check" style={{fontSize:"12pt"}}>     
                <label className="chklabel">
                    <input type="checkbox" id="childproof" 
                        onClick={this.handleChange} value="false"/>{config.app.verbiage.smithingPage.hideFromchildren}                    
                </label>
                </div>
                <div className="form-check" style={{fontSize:"12pt"}}>      
                <label className="chklabel">
                    <input type="checkbox" id="slang"  
                        onClick={this.handleChange} value="false"/>{config.app.verbiage.smithingPage.slang}
                </label>
                </div>

                <div className="form-group">

                </div> 

				<div className="container">
                    <span>
					<label>{config.app.verbiage.smithingPage.uploadImage}</label>
                        <label className="image-upload" htmlFor="input" 
                            style={{alignContent:"left",fontSize:"12pt",color:"turquoise"}}
                            >
                            <i className="material-icons" style={{color:"turquoise"}}>add_photo_alternate</i>
                           {config.app.verbiage.smithingPage.fromComputer}
					    </label>
                    </span>
					<div className="img-holder">
                        <img
                            style={{maxWidth:"100%", maxHeight:"100%", borderRadius:"5px"}} 
                            src={this.state.profileImg} alt="" id="img" className="" />
					</div>
					<input type="file" accept="image/*" name="image-upload" id="input" onChange={this.imageHandler} />
				</div>
               <div>&nbsp;</div>
                <div className="form-group" style={{width:"50%"}}>    
                <label id="lblPartOfSpeech">{config.app.verbiage.smithingPage.partOfspeech}</label><label id="lblRedPartOfSpeech" style={{color:"red"}}>*</label>
                <select className="form-control" id="partOfSpeech" onChange={this.handleChange} required>
                    <option></option>
                    <option>abbreviation</option>
                    <option>adjective</option>
                    <option>adverb</option>
                    <option>conjunction</option>
                    <option>interjection</option>
                    <option>noun</option>
                    <option>phrase</option>
                    <option>preposition</option>
                    <option>pronoun</option>
                    <option>verb</option>
                </select>
                </div>

                <div className="form-group"  style={{width:"50%"}}>
                    <label>{config.app.verbiage.smithingPage.pronunciation}</label>
                    <input type="text" id="pronunciation" value={this.state.pronunciation} onChange={this.handleChange} className="form-control" placeholder="Type a phonetic pronunciation" />
                </div>

                <div className="form-group">
                    <label id="lblDefinition">{config.app.verbiage.smithingPage.definition}</label><label id="lblRedDefinition" style={{color:"red"}}>*</label>
                    <textarea id="definition" className="form-control" 
                        dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                        cols="20" rows="7"
                        value={this.state.defintion} onChange={this.handleChange} 
                        placeholder="Type your definition here" required 
                        onKeyDown={this.onKeyDownDescription}
                    />
                </div>

                <div className="form-group">
                    <label id="lblUsage">{config.app.verbiage.smithingPage.exampleSentence}</label><label id="lblRedUsage" style={{color:"red"}}>*</label>
                    <textarea id="usage" className="form-control" 
                        dir={config.app.lang === "msa" ? "rtl" : "ltr"}
                        cols="20" rows="4"
                        value={this.state.usage} onChange={this.handleChange} 
                        placeholder="Type an example sentence" required />
                        
                </div>

                <div>
                
                </div>

                <div className="form-group">
                    </div>

            </form>
            <span>
                <Button 
                className="btn btn-primary btn-block"
                onClick={this.handleShow} style={{width:"150px",display:"inline-block"}}>
                    {config.app.verbiage.smithingPage.addWord}
                </Button>&nbsp;&nbsp;
                
                <input id="chkPostToTwetch" type="checkbox" onChange={e => this.setSelected(e.target.checked)} style={{display : "none"}}/>
                <span style={{display : "none"}}>&nbsp;Post to Twetch</span>
            </span>
            <div>
            <Modal show={this.state.show} onHide={this.handleClose} >
        <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
          <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Submit New Word Definition</Modal.Title>
        </Modal.Header> 
        <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
            <div id="modalTwetch" style={{color:"white",backgroundColor:"#202020"}}>
                {this.state.twetchChecked ?
                <div>
                    <div><span>You have chosen to post this definition to Twetch.</span></div>
                    <div><span>The post will appear in @7902.</span></div>
                    <div><span>&nbsp;</span></div>
                    <div><span>The fee includes:</span></div>
                    <div>
                        <ul style={{marginLeft:"25px"}}>
                            <li>$0.01 to create a word</li>
                            <li>$0.03 to post it to Twetch</li>
                        </ul>
                    </div>
                    <div>
                        <span>Add a message to the post (optional)</span>
                        <span><input type="text" id="twetchMessage" maxlength="100" style={{borderRadius: "10px",width:"400px"}}></input></span>
                        <div><span>&nbsp;</span></div>
                    </div>

                </div>
                   : " "
                }
                <MoneyButton
                        to={toPaymail}
                        amount={amount}
                        currency={currency}
                        onPayment={this.myOnPaymentCallback.bind(payment)}
                        label="to Add a Word"
                        buttonId="SLictionary"
                        buttonData="{app: SLictionary}"
                        opReturn="SLic"
                    />
            </div>

        </Modal.Body>
      </Modal>
      
     
            </div>
    </div>
      
    )};
  }


export default CreateWord;