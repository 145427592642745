import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

var imgUrl = "";

class RefineSearch extends Component {

  constructor(props) {
    super(props);

    let arr = this.props.location.pathname.split("/");
    this.state = {
      word: arr[2],
      txid: arr[3]
    };    

  }

  render() {

    return (

      <div className="content" style={{color:"white"}}>
          <span>Refine your search</span>
      </div>
    );
  }
}

export default RefineSearch;