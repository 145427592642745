const capitalizeFirstLetter = (function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  });

const wrap = (function(s,w) {  
    console.log(s.replace(
        new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n'
    ))
    return s.replace(
        new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n'
    )
});

const encodeAsUTF8 = (function(text){
    return encodeURIComponent(text);
});

const copyToClipboard = (function(id) {
    /* Get the text field */
    alert(id);
    alert(document.getElementById(id));
    var copyText = document.getElementById(id);
  
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
  
    /* Copy the text inside the text field */
    document.execCommand("copy");
  
    /* Alert the copied text */
    return copyText.value;
  
});

const getTruncatedSenderPaymail = function(senderPaymail){
    let arr = senderPaymail.split("@");

    if(arr[0].length > 12){
        senderPaymail = arr[0].substring(0,11) + "~" + "@" + arr[1];
    }

    return senderPaymail;
}


export {wrap, encodeAsUTF8, copyToClipboard, capitalizeFirstLetter, getTruncatedSenderPaymail};

