// config.js
const config = {
    app: {
        wordBountyDuration: 10,
        wordBountyTimeUnits: "days",
        lang: "msa",
        verbiage: {
            leaderboard: "المتصدرين",
            login: "تسجيل الدخول",
            logout: "تسجيل خروج",
            wordBounty: "كسب المال على الكلمات",
            moneyButtonSearch: "1 ملي ساتوشي",
            about: "معلومات عنا",
            ourPhilosophy: "فلسفتنا",
            words: "عدد الكلمات",
            contactUs: "اتصل بنا",
            howItWorks: "كيف تعمل",
            iCanDoBetter: "يمكنني أن أفعل أفضل",
            lookUpWord: "ابحث عن كلمة",
            smithingPage: {
                addAword: "أضف كلمة",
                beAwordsmith: "كن صائغًا",
                word: "كلمة",
                hideFromchildren: "إخفاء عن الأطفال",
                slang: "دارجة",
                uploadImage: "تحميل الصور",
                fromComputer: "من الكمبيوتر",
                partOfspeech: "جزء من الكلام",
                pronunciation: "النطق",
                definition: "تعريف",
                exampleSentence: "مثال على الجملة",
                addWord: "أضف كلمة"
            },
            wordBountyPage: {
                watchOurTutorial: "شاهد البرنامج التعليمي الخاص بنا",
                payToDefineWords: "قاموس المستثمرين ، كلمة البارونات ، يدفعون لك لتعريف الكلمات!",
                mustGetMostVotes: "يجب أن يحصل إدخال التعريف الخاص بك على أكبر عدد من الأصوات للفوز.",
                termsOfService: "شروط الخدمة",
                tipsForWinning: "نصائح للفوز!",
                activeBounties: "المكافآت النشطة",
                completedBounties: "الجوائز المنجزة"
            }
        }
    },
    bsv: {
        opReturnKey: "19HxigV4QyBv3tHpQVcUEQyq1pzZVdoAut",
        satoshisPerBsv:  100000000,
        mapiKey: "mainnet_6ae6d243fb38ec8a69a1bd2748ab8efc"
    },
    cors: {
        allowedOrigins : [
            'http://localhost:3000',
            'https://slictionary-test-2b1f1.web.app',
            'https://www.slictionary.com',
            'https://msa.slictionary.com'
       ]
    },
    firebase: {
        firebaseConfig : {
            apiKey: "AIzaSyDdxOcV41D3w8YYZfnbqwPrJQHkP5JXHyk",
            authDomain: "slictionary-msa.firebaseapp.com",
            projectId: "slictionary-msa",
            storageBucket: "slictionary-msa.appspot.com",
            messagingSenderId: "510592623588",
            appId: "1:510592623588:web:5a36c06b162acb102bddf1",
            measurementId: "G-FC8H2TG922"
          },
        serviceAccountFile: "slictionary-msa-firebase-adminsdk-ce09y-b151d2074d.json"
    },
    url: {
        tokenService: "https://us-central1-slictionary-msa.cloudfunctions.net/token",
        voteService: "https://us-central1-slictionary-msa.cloudfunctions.net/vote",
        bsvService: "https://us-central1-slictionary-msa.cloudfunctions.net/bsv",
        wordBountyService: "https://us-central1-slictionary-msa.cloudfunctions.net/wordBounty",
        wordService: "https://us-central1-slictionary-msa.cloudfunctions.net/word",
        setAllWbStatusToCompleted: "https://us-central1-slictionary-msa.cloudfunctions.net/wordBounty/word/all/status/completed",
        wocMainAddress: "https://api.whatsonchain.com/v1/bsv/main/address",
        userService: "https://us-central1-slictionary-msa.cloudfunctions.net/user"
    },
  }
  
  module.exports = config