import React,{useState, useEffect} from "react";  
import Modal from 'react-bootstrap/Modal';
import {Spinner} from 'spin.js';

const config = require("../../config");
const axios = require("axios");

function Wallet(props){  
    const [toggle, setToggle] = useState(false);
    const [balance, setBalance] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        document.body.style.backgroundImage = "url('')";
        getBalance();
    }
    , []);   
    
    const getBalance = async() => {
        let url = `${config.url.bsvService}/utxos/balance/${props.address}`;
        const json = await axios.get(url)
            .then (resp => {
                let satoshis = Math.round(resp.data.balance.satoshis);
                setBalance( ((Number(satoshis))/1000).toFixed(2).toLocaleString('en-US') + " ksats" );
            });
    }
    
    const handleShow = (e) => {
        setToggle(true);
        return false;
    }
    
    const handleClose = (e) => {
        setToggle(false);
    }
    
    const receiveClick = (e) => {
        navigator.clipboard.writeText(props.address);
        alert(`Your wallet address ${props.address} has been copied to the clipboard`);
    }

    const sendClick = async(e) => {
        try{
            var spinner = new Spinner(props.spinnerOpts);
            spinner.spin(document.getElementById("sendBsv"));
            let i = document.getElementById("iSendSpinner");
                i.removeAttribute("hidden");
            let btn = document.getElementById.bind("btnSend");
            btn.innerHTML = "Processing";
            let usdAmount = Number(document.getElementById("txtUSDamount").value);
            let exchangeRate = Number(sessionStorage.getItem("exchangeRate"));
            let satAmount = Math.round(usdAmount/exchangeRate * 100000000); 
            let privateKey = localStorage.getItem("privateKey");
            let toAddress = document.getElementById("txtToAddress").value;
            let json = {
                "fundingPrivateKey" : privateKey,
                "toAddress" : toAddress,
                "satAmount" : satAmount,
                "opReturn" : "0"
            }
            //const res = await axios.post(`${config.url.bsvService}/payment/send`,json);
            await axios.post(`${config.url.bsvService}/payment/send`,json)
                .then(function (res) {
                    alert("BSV sent");
                })
                .catch(function (error) {
                    alert("BSV send failed");
                    console.log(JSON.stringify(error));
                });
            //this.setState({show:!this.state.show});
            setToggle(!toggle);
            setReload(true);
            spinner.stop();
        } catch (err) {
            setToggle(false);
            spinner.stop();
            console.log(JSON.stringify(err));
        }
    }
    return (
        <>
            <span style={{fontFamily:"Cambria", fontSize: "20pt"}}>Wallet</span>
            <div>&nbsp;</div>
            <div><b>{(balance)}</b> </div>
            <div className="button-container" id="sendBsv">
                <button type="button" className="btn btn-primary" 
                    onClick={handleShow} style={{backgroundColor:"black", borderColor:"white", 
                                                        padding:"10px 20px", width:"100px",
                                                        }}>
                        Send
                </button>
                <button 
                    onClick={receiveClick}
                    type="button" 
                    className="btn btn-primary" 
                    style={{backgroundColor:"black", borderColor:"white", width:"100px"}}>
                        Receive
                </button>
            </div>
            <Modal show={toggle} onHide={handleClose} >
                <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
                    <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Send BSV</Modal.Title>
                </Modal.Header> 
                <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
                    <div id="modal" style={{color:"white",backgroundColor:"#202020"}}>
                        <div>
                            <div>
                            <form>
                                <div><span><input type="text" id="txtToAddress" required maxlength="100" style={{borderRadius: "0px",width:"100%", height:"50px", padding:"5px"}} placeholder="Enter recipient address"></input></span></div>
                                <div><span>&nbsp;</span></div>
                                <div><span><input type="text" id="txtUSDamount" maxlength="100" style={{borderRadius: "0px",width:"100%",height:"50px", padding:"5px"}} placeholder="Enter amount to send in USD"></input></span></div>
                                <div><span>&nbsp;</span></div>
                                <div><span>
                                    <button id="btnSend" type="button" onClick={sendClick} className="btn btn-primary" 
                                        style={{width:"100%",height:"50px", backgroundColor:"black", 
                                        borderColor:"white"}}>
                                        <i hidden id="iSendSpinner" className="fa fa-spinner fa-spin"></i>
                                        &nbsp;Send
                                    </button>
                                    </span>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
      </>
    )
}  

export default Wallet;  