import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = {};

const config = {
    apiKey: "AIzaSyDdxOcV41D3w8YYZfnbqwPrJQHkP5JXHyk",
    authDomain: "slictionary-msa.firebaseapp.com",
    projectId: "slictionary-msa",
    storageBucket: "slictionary-msa.appspot.com",
    messagingSenderId: "510592623588",
    appId: "1:510592623588:web:5a36c06b162acb102bddf1",
    measurementId: "G-FC8H2TG922"
};

firebase.initializeApp(config);

firebase.firestore().settings(settings);

const auth = firebase.auth();
auth.signInWithEmailAndPassword("johndifelice@protonmail.com", "google2940")
.then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
  
export default firebase;