import React from 'react';
import 'bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import firebase from './Firebase';
import {getWords, getWordId, wordExists} from './components/Common/Words';
import { UserContext } from './components/UserContext';
import { getWalletInfo } from './mods/slictionary-user-mod';
import { getVotes, getVoteEarnings } from './mods/slictionary-vote-mod';
import {getWordsByUser} from './components/Common/Words';
import { impersonate } from './mods/slictionary-user-mod';
import imgUAEflag from './assets/UAE-flag-circle.png';
import imgUSAflag from './assets/USA-flag-circle.png';
import imgPlus from './assets/imgPlus.png';

const axios = require("axios");
const config = require("./config");

var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;

var amount = "5";

class App extends React.Component{
  
  constructor(props) {
    super(props);
    this.myOnPaymentCallback = this.myOnPaymentCallback.bind(this);
    this.onMoneyButtonLoad = this.onMoneyButtonLoad.bind(this);
    this.addWordOnClick = this.addWordOnClick.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.listClick = this.listClick.bind(this);
    this.clic = this.clic.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.weaveOnClick = this.weaveOnClick.bind(this);

    this.state = {
      words: [],
      senderPaymail: "",
      refresh: ""
    };

    
    this.getWordArray();

  }

  componentWillMount(){
    document.body.style.backgroundImage = 
        config.app.lang === "msa" ? "" : "url('https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/Word%20Vortex.gif?alt=media&token=22365c9e-9ceb-4bbc-8320-1af2d436b5e3')";

}

  async componentDidMount(word) {

    if(localStorage["senderPaymail"] !== undefined){
        let senderPaymail = localStorage.getItem("senderPaymail");
        // let wordsByUser = await getWordsByUser(senderPaymail).then(function(wordsByUser){
        //     sessionStorage.setItem("wordsByUser",wordsByUser);
        // });
    } else {
        //sessionStorage.removeItem("wordsByUser");
    }

    sessionStorage.setItem("previousUrl","");

    document.body.style.backgroundSize = "cover";     
    document.body.style.height = "100vh";
    document.body.style.padding = "0";
    document.body.style.margin = "0"; 
}

addWordOnClick = event =>{
  this.props.history.push({
    pathname:"createword/@/@/@"
  });
}

weaveOnClick = event => {
}

  async myOnPaymentCallback(payment){
    this.setState({senderPaymail: payment.senderPaymail});
    localStorage.setItem("senderPaymail",payment.senderPaymail);
    if(localStorage["walletAddress"] === undefined){
        let results = await getWalletInfo(payment.senderPaymail);
        localStorage.setItem('senderPaymail',payment.senderPaymail);
        localStorage.setItem('walletAddress', results.walletAddress);
        localStorage.setItem('privateKey', results.privateKey);
    }
    //impersonate("knightonaholycrusade@moneybutton.com","13Z6oWzERtUWJiWUtwcqPJhDpNjcxJzWmY","KxZdatuot585vMdRxbw1Bmu5WjWR8Bvpu5oZHrpCkw8BxyG4dKbd");    

    //impersonate("eolio@moneybutton.com","15gpbB39HExJK5YKUd2FzK6R6nnniMLadF","L5MX21VwQNdto1LsNcJvXq7CJ1MBwQJHvVxmUN1KNcoskwC9WpKL");    
    //impersonate("lmazur@moneybutton.com","1ch98AGYDGqWdP18LhsuW8mG3v3Fn6D99","Kx8wDLWcLSGHaX42Wzazib9fdc7J3u2PCekTF5K3SXAV2w7WPb92");    
    //impersonate("31897@moneybutton.com","17xDDfWP4WhESHSt9v6qtZRmd2tv6SzfJq","KzBUcNGapP5sKe1oGnoVxtDyx61sE8HXH4h9VoNfv24PdxhQe433");    
    //impersonate("coingeekofficial@moneybutton.com","1Nge7sG7Kv3BrxcRwmzkaJPs18wYHqwozm","L2e4Z8HZrqra8SwSKYfzxFPPX8Cxk4XQwFcHux6gVuMMPgn2nSsVKzg64hEqyraQoLJjAuUgx5M8pP2p69c2eu2hU82rk277RSmdg9SQ");
    //impersonate("johnfcoyleiii@moneybutton.com","13sXWX9HgQWVUZSAaAMe5HNiMAjWkKkaSb","L554hhTEjr9xGkrU7atBRMS3yFfdVv7mhxRBYahP3Jmazz1t7iiX");
    //impersonate("satoshinakamojo@moneybutton.com","1J35U8JGNo9ACH3wukKbztpfUBBkP9B3Hd","KzxLPREFUWppy1ncQ1NJcJzTyCjAZ8uJH4jJyXv8ft4SjQwM69Tq");
    let db = firebase.firestore();
    var word = document.getElementById('txtSearch').value.toLowerCase().trim();

    if(word.length === 0){
      alert("You must enter a word to search");
      return false;
    }
    const usersRef = db.collection('words').doc(word);

    var doc = await usersRef.get();

    let ID = await getWordId(word);
    let bWordExists = await wordExists(word);
           
    if (bWordExists){
        //word exists.
        this.props.history.push({
          pathname:"testit/" + word + "/" 
              + payment.txid
      });
    } else {
        //word does not exist. goto Lucky You page.
        this.props.history.push("searchresults/" 
        + word 
        + "/" + payment.txid);
    }

    db = firebase.firestore();

    db.collection("revenue").doc("revenueDoc").collection("search").add({
        userId: payment.senderPaymail,
        datetime:  firebase.firestore.FieldValue.serverTimestamp(),
        word: word,
        txId: payment.txid,
        amount: payment.amount
    }).then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });

  }

listClick = event =>{
  let txt = document.getElementById("txtSearch");
  let ul = document.getElementById("myUL");
  txt.value = event.target.innerText;
  ul.style.display = 'none';
}

clic = event =>{
    alert("iframe click");
    return false;
}
onMoneyButtonLoad = () =>{
  var iframes = document.getElementsByTagName('iframe');
  iframes[0].className = "searchFrame";
 
}

myFunction = (event) => {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById("txtSearch");
  filter = input.value.toUpperCase();
  ul = document.getElementById("myUL");
  
  if(input.value.length > 0){
    ul.style.display = "block";
  } else {
    ul.style.display = "none";
  }

  li = ul.getElementsByTagName("li");
  for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
      } else {
          li[i].style.display = "none";
      }
  }

  var lis = document.getElementById("myUL").getElementsByTagName("li")
  var count = 0;
  let k = 0;
  for(k===0; k < lis.length; k++) {
    if(lis[k].style.display !== "none"){
      count += 1;
    }
  };
  
  if (count > 5){
    ul.style.height = "250px";
  }else if(count === 0){
    ul.style.display = "none";
  }else {
    ul.style.height = (count * 50).toString() + "px";
  }
}

onKeyDown = (event) => {
    switch (event.keyCode) {
        case 37:
             alert('Left key pressed');
           break;
        case 38:
             alert('Up key pressed');
           break;
        case 39:
             alert('Right key pressed');
           break;
        case 40:
            document.getElementById("myUL").focus();
           break;
     }
}

getWordArray = async () => {
  let arr = await getWords();
  this.setState({words:arr});
  
}
  render() {
    return (


           <Container id="parentContainer">
         

          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>
          
          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>

          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>

          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>
 
          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp; </Col>
          </Row>
          <Row className="row align-items-center">
              <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
              <Col className="col-sm-9 col-lg-9 col-xs-11" style={{whiteSpace:"nowrap",color:"red", fontSize:"20pt"}}>
              </Col>
          </Row>
          
            <Row className="row align-items-center">
              <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
              <Col className="col-sm-9 col-lg-9 col-xs-11" style={{whiteSpace:"nowrap"}}>
            <Container style={{width: "1100px"}}>
                <Row><Col>
                {/* <a href="./createword/@/@/@" style={{"zIndex":"9999999"}}><img id="iAddWord" src={imgPlus} title="Smith a word"/></a> */}
                
                <i id="iAddWord" className="fa fa-plus-circle fa-2x" onClick={this.addWordOnClick} title="Smith a Word"/>
                
                <a href={config.app.lang === "msa" ? "https://www.slictionary.com/" : "http://msa.slictionary.com"} title={config.app.lang === "msa" ? "to English site" : "to Modern Standard Arabic site"}>
                    <img id="imgEnglishSite" src={config.app.lang === "msa" ? imgUSAflag : imgUAEflag} alt="English site"/> 
                </a>
                
                <input type="text" id="txtSearch" className="col-sm-7 col-lg-11 col-xs-12" 
                    onKeyUp={this.myFunction} 
                    rows="1" cols="100"
                    autoComplete="off" 
                    placeholder={config.app.verbiage.lookUpWord}
                    style={{borderWidth:"1px", borderColor:"white"}}
                   >
                </input>
                <div id="btnSeek">
                <MoneyButton
                        id="btnSeek"
                        to="selflearningdictionary@MoneyButton.com"
                        amount="0.0096"
                        currency="USD"
                        onPayment={this.myOnPaymentCallback.bind(payment)}
                        label={config.app.verbiage.moneyButtonSearch}
                        onLoad={this.onMoneyButtonLoad}
                        buttonId=""
                        buttonData="{app: SLictionary}"
                        opReturn="SLic"
                />
                </div>
                </Col>
                <Col>
              <div id="btnWeave" dangerouslySetInnerHTML={{__html: "<button id='weave-widget-trigger' class='weave btn btn-dark' onClick='weave.show()' >Need BSV?</button>"}} />                 
              </Col>
              </Row>
              </Container>
              
              </Col>    
              </Row>
              <Row >
                  
              <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
                <Col className="col-sm-9 col-lg-9 col-xs-11" id="rowLookAhead">
                <ul id="myUL" onClick={this.listClick}>
                {this.state.words.map((word,i) =>
                            <li key={i}>
                                <a href="#">{word}</a></li>
                        )}
               
              </ul>
                </Col>
              </Row>
              <Row>
                <Col className="col-sm-3">&nbsp;</Col>
                <Col className="col-sm-9"></Col>
              </Row>

              
              <UserContext.Provider value={this.state.senderPaymail}>
              </UserContext.Provider>
          </Container>

    
    );
  }

}

export default App;

