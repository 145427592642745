import React from 'react';
import {getDefinitions} from './Version';


class DefinitionCard extends React.Component {

    constructor(props) {
      super(props);
      this.voteClick = this.voteClick.bind(this);
     
      this.cool();
    }

    async cool(){
        //let versions = await getDefinitions("sugar","-M4LzJlp_alC7mMeWfD_",this.props.docRef);//this is the id of the top definition
    }
 

    // voteClick = event =>{

    // }

    render() { 

      return (
        <div className="content" style={{color:"white"}}>
      
      <div id="demo" className="carousel slide" data-ride="carousel">

        <ul className="carousel-indicators">
        <li data-target="#demo" data-slide-to="0" className="active"></li>
        <li data-target="#demo" data-slide-to="1"></li>
        <li data-target="#demo" data-slide-to="2"></li>
        </ul>

        <div className="carousel-inner">
        <div className="carousel-item active">
            <img src="https://www.w3schools.com/bootstrap4/chicago.jpg" style={{width:"1100", height:"500"}} />
        </div>
        <div className="carousel-item">
            <img src="https://www.w3schools.com/bootstrap4/la.jpg" style={{width:"1100", height:"500"}} />
        </div>
        <div className="carousel-item">
            <img src="https://www.w3schools.com/bootstrap4/ny.jpg" style={{width:"1100", height:"500"}} />
        </div>
        <div className="carousel-item">
            <img src="http://localhost:3000/DefinitionCard/ " style={{width:"1100", height:"500"}} />
        </div>
        </div>


        <a className="carousel-control-prev" href="#demo" data-slide="prev">
        <span className="carousel-control-prev-icon"></span>
        </a>
        <a className="carousel-control-next" href="#demo" data-slide="next">
        <span className="carousel-control-next-icon"></span>
        </a>

    </div>











            <div style={{ 
                display:"flex",
                padding:'5px',
                borderRadius:'15px',
                border:'0.5px solid gray',
                width:'500px',
                background:'black',
                color:'white'}}> adfasdfasdfasdf 
            </div>
            <div style={{display:"flex"}}>author: jdifelice</div>
            <i class="fa far fa-lightbulb fa-1x" title="Like"></i>
                
        </div>     
      );
    }
  
}

export default DefinitionCard;