import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import illegalNavigation from '../assets/illegal navigation.jpg';

class ErrorInvalidNavigation extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount(){
    document.body.style.backgroundImage = "url('')";
  }
  
  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"yellow"}}>
          <h1>WHoops!</h1>
          <p>&nbsp;</p>
          <p>&nbsp;</p>

          <img alt="" src={illegalNavigation}/>
          <h2 style={{color:"yellow"}}>You have performed an invalid navigation</h2>
          
      </div>
    );
  }
}

export default ErrorInvalidNavigation;