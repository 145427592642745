import React from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import firebase from '../../Firebase';
import {getWords, getWordId, wordExists} from '../Common/Words';
import { UserContext } from '../UserContext';
import twetchjs from '@twetch/sdk';
const bsv = require('bsv');

var MoneyButton = require('@moneybutton/react-money-button').default;
var payment;

class twetchPost extends React.Component{
  
  constructor(props) {
   
    super(props);
 
    this.myOnPaymentCallback = this.myOnPaymentCallback.bind(this);
    this.onMoneyButtonLoad = this.onMoneyButtonLoad.bind(this);
    this.addWordOnClick = this.addWordOnClick.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.listClick = this.listClick.bind(this);
    this.clic = this.clic.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.state = {
      words: [],
      senderPaymail: "",
      refresh: ""
    };

    
    this.getWordArray();

  }

  componentWillMount(){
    //document.body.style.backgroundImage = "url('')";
  }
  async componentDidMount(word) {
        sessionStorage.setItem("previousUrl","");

        document.body.style.backgroundImage = "url('https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/Word%20Vortex.gif?alt=media&token=22365c9e-9ceb-4bbc-8320-1af2d436b5e3')";
        document.body.style.backgroundSize = "cover";     
        document.body.style.height = "100vh";
        document.body.style.padding = "0";
        document.body.style.margin = "0"; 
        
        // try{
        //     const sdk = new twetchjs();
        //     const Twetch = require("@twetch/sdk");
        //     var options = {clientIdentifier:"bbaf2dac-774c-4741-9f04-81d3ad3df2f6"};
        //     const twetch = new Twetch(options);
        //     const content = 'Hello World from Twetch SDK w/ MoneyButton';
        //     //twetch.init();
        //     //const { abi, payees } = await sdk.build('twetch/post@0.0.1', { bContent: 'Hello World from Twetch SDK w/ MoneyButton' });
        //     //const abi = result.abi;
        //     //const payees = result.payees;
        //     const outputs = [{
        //         currency: 'BSV',
        //         amount: 0,
        //         //script: bsv.Script.buildSafeDataOut(abi.toArray()).toASM()
        //         script: bsv.Script.buildSafeDataOut([content, '#{mySignature}', '#{myPubKey}', '#{myPaymail}'])//.toAsmString()
        //     }];//.concat(payees);
    
        //     const cryptoOperations = [
        //         {
        //             name: 'myAddress',
        //             method: 'address',
        //             key: 'identity'
        //         },
        //         {
        //             name: 'mySignature',
        //             method: 'sign',
        //             data: abi.contentHash(),
        //             dataEncoding: 'utf8',
        //             key: 'identity',
        //             algorithm: 'bitcoin-signed-message'
        //         }
        //     ];
        // } catch(error){
        //     console.log(error)
        // }
        
}

addWordOnClick = event =>{
  this.props.history.push({
    pathname:"createword/@/@/@"
  });
}

  async myOnPaymentCallback(payment){
    this.setState({senderPaymail: payment.senderPaymail});

    localStorage.setItem('senderPaymail',payment.senderPaymail);

    let db = firebase.firestore();
    
    var word = document.getElementById('txtSearch').value.toLowerCase().trim();

    if(word.length === 0){
      alert("You must enter a word to search");
      return false;
    }
    const usersRef = db.collection('words').doc(word);

    var doc = await usersRef.get();

    let ID = await getWordId(word);
    let bWordExists = await wordExists(word);
           
    if (bWordExists){
        //word exists.
        this.props.history.push({
          pathname:"testit/" + word + "/" 
              + payment.txid
      });
    } else {
        //word does not exist. goto Lucky You page.
        this.props.history.push("searchresults/" 
        + word 
        + "/" + payment.txid);
    }

    db = firebase.firestore();

    db.collection("revenue").doc("revenueDoc").collection("search").add({
        userId: payment.senderPaymail,
        datetime:  firebase.firestore.FieldValue.serverTimestamp(),
        word: word,
        txId: payment.txid,
        amount: payment.amount
    }).then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });

  }

listClick = event =>{
  let txt = document.getElementById("txtSearch");
  let ul = document.getElementById("myUL");
  txt.value = event.target.innerText;
  ul.style.display = 'none';
}

clic = event =>{
    alert("iframe click");
    return false;
}
onMoneyButtonLoad = () =>{
  var iframes = document.getElementsByTagName('iframe');
  iframes[0].className = "searchFrame";
 
}

myFunction = (event) => {
  // if(event.which == '13'){
  //   alert('yo');
  //   event.preventDefault();
  // }
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById("txtSearch");
  filter = input.value.toUpperCase();
  ul = document.getElementById("myUL");
  
  if(input.value.length > 0){
    ul.style.display = "block";
  } else {
    ul.style.display = "none";
  }

  li = ul.getElementsByTagName("li");
  for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
      } else {
          li[i].style.display = "none";
      }
  }

  var lis = document.getElementById("myUL").getElementsByTagName("li")
  var count = 0;
  let k = 0;
  for(k===0; k < lis.length; k++) {
    if(lis[k].style.display !== "none"){
      count += 1;
    }
  };
  
  if (count > 5){
    ul.style.height = "250px";
  }else if(count === 0){
    ul.style.display = "none";
  }else {
    ul.style.height = (count * 50).toString() + "px";
  }
}

onKeyDown = (event) => {
    switch (event.keyCode) {
        case 37:
             alert('Left key pressed');
           break;
        case 38:
             alert('Up key pressed');
           break;
        case 39:
             alert('Right key pressed');
           break;
        case 40:
             //alert('Down key pressed');
            document.getElementById("myUL").focus();
           break;
     }
}

getWordArray = async () => {
  let arr = await getWords();
  this.setState({words:arr});
  
}
  render() {
    
    return (


           <Container id="parentContainer">
         

          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>
          
          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>

          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>

          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>
 
          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>

          <Row className="row justify-content-start">
              <Col className="col-12">&nbsp;</Col>
          </Row>
          
            <Row className="row align-items-center">
              <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
              <Col className="col-sm-9 col-lg-9 col-xs-11" style={{whiteSpace:"nowrap"}}>

              <i id="iAddWord"                    
                    className="fa fa-plus-circle fa-2x" onClick={this.addWordOnClick} title="Smith a Word"
                    style={{position:"relative",top:"-6px"}}></i>
                
                <input type="text" id="txtSearch" className="col-sm-7 col-lg-7 col-xs-12" 
                    onKeyUp={this.myFunction} 
                    rows="1" cols="50"
                    autoComplete="off" 
                    placeholder="Look up a word"
                    style={{borderWidth:"1px", borderColor:"white"}}
                   >
                </input>
                
                <MoneyButton
                        to="selflearningdictionary@MoneyButton.com"
                        amount="0.0096"
                        currency="USD"
                        onPayment={this.myOnPaymentCallback.bind(payment)}
                        label="to Search"
                        onLoad={this.onMoneyButtonLoad}
                        buttonId=""
                        buttonData="{app: SLictionary}"
                        opReturn="SLic"
                />
                
              </Col>
              </Row>
              <Row>
                  
              <Col className="col-sm-3 col-lg-3 col-xs-1">&nbsp;</Col>
                <Col className="col-sm-9 col-lg-9 col-xs-11">
                <ul id="myUL" onClick={this.listClick}>
                {this.state.words.map((word,i) =>
                            <li key={i}>
                                <a href="#">{word}</a></li>
                        )}
               
              </ul>
                </Col>
              </Row>
              <Row>
                <Col className="col-sm-3">&nbsp;</Col>
                <Col className="col-sm-9"></Col>
              </Row>

              
              <UserContext.Provider value={this.state.senderPaymail}>
              </UserContext.Provider>
          </Container>

    
    );
  }
}




export default twetchPost;

