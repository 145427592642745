import React from 'react';
import firebase from '../Firebase';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './SearchResults.css';
import {UserContext} from './UserContext';
import {addBounty} from '../mods/slictionary-word-bounty-mod';
import newLogo from '../assets/NEW2.png'

const {getBSVexchangeRate} = require('../mods/slictionary-bsv-mod');
const { saveTokens } = require('../mods/slictionary-token-mod');

var MoneyButton = require('@moneybutton/react-money-button').default;
var moment = require('moment');
var payment;
var exchangeRate = 0;


class SearchResults extends React.Component  {

    constructor(props) {
        super(props);
        this.addWordOnClick = this.addWordOnClick.bind(this);
        this.myOnPaymentCallback = this.myOnPaymentCallback.bind(this);
        this.sendBountyOnClick = this.sendBountyOnClick.bind(this);
      
        let arr = this.props.location.pathname.split("/");
        this.state = {
            word: arr[2],
            txid: arr[3],
            order:0,
            imgUrl:"",
            pronunciation:"",
            definition:"",
            rows: [],
            defId: [],
            bountyData: [],
            paymail:localStorage.getItem("senderPaymail"),
            email:localStorage.getItem("senderPaymail") //default value
        };
        console.log(this.state.paymail);
        this.cool();

    }

    componentWillMount(){
        if(sessionStorage.getItem("lastTxId") === this.state.txid){
            this.props.history.push("/");
            this.props.history.push({pathname:"error-pages/error-invalid-navigation"});
        }
        document.body.style.backgroundImage = "url('')";
    }

    async componentDidMount(word) {
        sessionStorage.setItem("lastTxId",this.state.txid);
        exchangeRate = await getBSVexchangeRate();
  }

    addWordOnClick = event =>{
        this.props.history.push("/");
        this.props.history.push({
          pathname:"createword/" + this.state.word + "/@/" + this.state.txid
        });
        console.log("about to upload file");
    }

    async sendBountyOnClick(event){
        let amount = 0.01; //the default bounty is 1 cent.
        var bountySatoshi = 0;
        var effectiveBounty = 0;
        var effectiveBountySatoshi = 0
    
        if(amount >= 2.0 && amount <= 11.0){
            effectiveBounty = amount - 1;
        }else if (amount > 11.0){
            effectiveBounty = amount * 0.9; //take 10% off
        } else {
            effectiveBounty = amount;
        }
    
        bountySatoshi = amount * 100000000 / exchangeRate;
        effectiveBountySatoshi = effectiveBounty * 100000000 / exchangeRate

        await addBounty(
            amount, //amount
            amount * 100000000 / exchangeRate, //amountSatoshi
            Number(exchangeRate), //exchangeRate
            "", //txid
            "", //moneyButtonId
            this.state.email, //senderEmail
            this.state.paymail, //senderPaymail
            moment().format(), //voteStart
            "", //winner
            this.state.word, //word,
            effectiveBounty,
            effectiveBountySatoshi
        );

        this.props.history.push({pathname:"/confirmation/wordbounty-thank-you/" + this.state.word});
    }


    cool = async () => {
        var db;

        var arr2 = [];
        var arrDefId = [];
        db = firebase.firestore();
        var docRef = db.collection("words");
        var query = docRef.where("firstThree", "==", this.state.word.substr(0, 3));
        let querySnapshots = await query.get();
        
        querySnapshots.forEach((function (doc){
            arr2.push(doc.id);
            arrDefId.push(doc.data().definitions[0].id);
        }));
        this.setState({defId: arrDefId});
        this.setState({rows:arr2});

    }

    async myOnPaymentCallback(payment){
      let amount = 0;
      amount = parseFloat(payment.amount)
      
      var effectiveBounty = 0;
      var effectiveBountySatoshi = 0
  
      //decrement the bounty by 1.0 if >=2 or <=11
      if(amount >= 2.0 && amount <= 11.0){
          effectiveBounty = amount - 1;
      }else if (amount > 11.0){
        effectiveBounty = amount * 0.9; //take 10% off
      } else {
          effectiveBounty = amount;
      }
  
      //bountySatoshi = amount * 100000000 / exchangeRate;
      effectiveBountySatoshi = effectiveBounty * 100000000 / exchangeRate

      addBounty(
        amount, //amount
        amount * 100000000 / exchangeRate, //amountSatoshi
        Number(exchangeRate), //exchangeRate
        "", //txid
        "", //moneyButtonId
        this.state.email, //senderEmail
        this.state.paymail, //senderPaymail
        moment().format(), //voteStart
        "", //winner
        this.state.word,
        effectiveBounty,
        effectiveBountySatoshi
      );
      
        //Create tokens
        // const txIds = await createTokens();
        // let date = moment().format();
        // await saveTokens(
        //     "",
        //     txIds.contractTxId,
        //     date,
        //     "[detail]",
        //     txIds.issueTxId,
        //     date,
        //     this.state.paymail,
        //     txIds.redeemSplitTxId,
        //     "redeemTxId",
        //     txIds.splitTxId,
        //     "STAS",
        //     txIds.transferTxId,
        //     this.state.word
        // );

      this.props.history.push({pathname:"/confirmation/wordbounty-thank-you/" + this.state.word});
    }

    render() { 

      return (
        <div className="content" style={{color:"white"}}>
          <Container style={{width:1000}}>
          <Row>
          <Col>
            <h3 className="justify-content-md-center">
            <div className="luckyYou text-center">Lucky You!</div>

            <div className="text-center"><span style={{color:"Turquoise",fontSize:"20pt"}}>{this.state.word}</span>
            &nbsp;is not in SLictionary. You can either...</div>
            </h3>
          </Col>
          </Row>
            <Row>
              <Col className="col-12">
              <div className="bountyPanel" style={{width:"40%"}}>
                <div className="bountyPanelInner"><h3 className="blackText heading" style={{fontWeight:"bold"}}>DEFINE IT & MAKE MONEY!</h3>
                <div style={{fontSize:"18pt", fontWeight: "bold",color:"green"}}>FREE</div>
                <div>&nbsp;</div>
                  <i style={{display: "block", cursor:"pointer"}} 
                      id="addWord" 
                      class="fas fa-plus-circle fa-5x" 
                      title="Define it" 
                      aria-hidden="true"
                      onClick={this.addWordOnClick}></i>
                </div>
                <div>&nbsp;</div>
                <div style={{textAlign: "left",whiteSpace:"normal",color:"black",margin:"20px"}}>
                    Your search fee has been applied to our Word$mith page. 
                    Write a definition for “<b>{this.state.word}</b>” and make money when others vote 
                    on it.
                </div>
              </div>
              <div className="bountyPanel">
                <div className="bountyPanelInner"><h3 class="blackText heading" style={{fontWeight:"bold"}}>SET WORD BOUNTY</h3></div>
                <div 
                    style={{position:"relative",marginLeft:"10px", color:"blue", 
                            fontWeight:"bold", fontsize:"36pt"}}>
                    <img src={newLogo}
                        style={{paddingLeft:"20px", width:"75px", height:"auto"}}
                    />
                    If you set a word bounty of $2 or more, 
                    <br/>the definitions will be converted to NFTs</div>
                  <table style={{width:"100%"}}>
                    <tr>
                      <td className="blackText" style={{marginRight:"10px",textAlign:"right"}}>
                      <span ><p>&nbsp;&nbsp;Set bounty at 1&cent;&nbsp;&nbsp;<span 
                          style={{fontSize:"18pt", fontWeight: "bold",color:"green"}}>FREE</span></p><p>(using your search fee)</p>
                      </span>
                      </td>
                      <td><i style={{color:"black"}} class="fa fa-arrow-right" aria-hidden="true"></i></td>
                      <td style={{textAlign:"left"}}>
                        <button className="btn btn-lg" style={{size:"10px",borderRadius:"30px", color:"white",
                              backgroundColor:"blue"}} 
                            onClick={this.sendBountyOnClick}>SET BOUNTY</button>
                      </td>
                    </tr>
                    <tr><td></td><td style={{color:"black",fontWeight:"bold"}}>OR</td><td></td></tr>
                    <tr>
                      <td>
                        <span className="blackText" 
                            style={{position:"relative",marginLeft:"10px", color:"blue", fontWeight:"bold"}}
                            >Sponsor the word&nbsp; </span>
                      </td>
                      <td>
                        <i style={{color:"black"}} class="fa fa-arrow-right" aria-hidden="true"></i>
                      </td>
                      <td>
                        <MoneyButton
                                to="selflearningdictionary@MoneyButton.com"
                                type="tip"
                                amount="0.0096"
                                editable="true"
                                currency="USD"
                                onPayment={this.myOnPaymentCallback.bind(payment)}
                                label="for Bounty"
                                buttonId=""
                                buttonData="{app: SLictionary}"
                                opReturn="SLic"
                        />
                      </td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <div style={{textAlign: "left",whiteSpace:"normal",color:"black"
                                , margin:"30px",marginTop:"0px"}}>
                                This word will go onto the 
                                Word Bounty list where 
                                Word$miths will define it for you. Higher bounties attract more WordSmiths and 
                                better definitions.
                            </div>
                            <div>&nbsp;</div>
                            <div style={{marginBottom:"10px", marginLeft:"-55px", marginTop:"-30px"}}>
                            </div>
                        </td>
                    </tr>
                </table>
                </div>
              </Col>
            </Row>
          </Container>  
        </div>
      );
    }
}

SearchResults.contextType = UserContext;

export default SearchResults;