import React from 'react';
import BaseComponent from '../Common/BaseComponent';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WordBountyTable from '../word-bounty/WordBountyTable';
import './WordBounty.css'
import Modal from 'react-bootstrap/Modal';
import newLogo from '../../assets/NEW2.png'

const config = require("../../config");

class WordBounty extends BaseComponent{

    
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            setShow: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        
      }

      componentWillMount(){
        document.body.style.backgroundImage = "url('')";
      }

    async componentDidMount(word) {
        //document.body.style.backgroundImage = "url('')";
    }

    handleShow = () => {
        this.setState({show:!this.state.show});
        return false;
    }

    handleClose = () => {
        this.setState({show:!this.state.show});
    }

    render() {

        return(
            
            <Container style={{width:1000}}>


      <Modal show={this.state.show} onHide={this.handleClose} >
        <Modal.Header closeButton style={{color:"white",backgroundColor:"#202020"}}>
          <Modal.Title style={{color:"white",backgroundColor:"#202020"}}>Tips For Winning a Bounty</Modal.Title>
        </Modal.Header> 
        <Modal.Body style={{color:"white",backgroundColor:"#202020"}}>
            <div style={{color:"white",backgroundColor:"#202020"}}>
                <ol style={{marginLeft:"25px"}}>
                    <li>Include an image</li>
                    <li>Include an audio file</li>
                    <li>Write an original example sentence</li>
                </ol>
                <span>That's all there is to it.</span>

            </div>

        </Modal.Body>
        <Modal.Footer style={{color:"white",backgroundColor:"#202020"}}>

        </Modal.Footer>
      </Modal>

            <Row>
            <Col>
              <div className="word-bounty text-center">{config.app.verbiage.wordBounty}</div>
              <div>&nbsp;</div>
              <div className="text-center">
              <span style={{textDecoration:"underline", color:"yellow"}}>
              <a style={{color: "yellow", fontSize:"20pt"}} href="./wbtermsofservice/" >{config.app.verbiage.wordBountyPage.termsOfService}</a>
                </span>
              </div>
            </Col>
            </Row>
            <Row>
                <Col>
                <div>&nbsp;</div>
                    <div className="wordBountyCopy">
                        <div>{config.app.verbiage.wordBountyPage.payToDefineWords}</div>
                        <div>{config.app.verbiage.wordBountyPage.mustGetMostVotes} 

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontStyle:"normal", color:"lightgoldenrodyellow",textDecoration:"underline"}} 
                            ><a href="#" onClick={this.handleShow}>{config.app.verbiage.wordBountyPage.tipsForWinning}</a></span>
                        </div>
                        <div>
                            <a style={{textDecoration:"underline", fontStyle:"normal"}} 
                                href="https://www.youtube.com/watch?v=2Yg4lsqtJLE&t=7s&start=1215">
                                    {config.app.verbiage.wordBountyPage.watchOurTutorial}
                            </a>
                        </div>
                    </div>  
                    <div>&nbsp;</div>
                    {/* <div>
                        <span style={{fontSize:"24pt",color:"red"}}>We are having trouble with a third party component on this page.</span> <br/>
                        <span style={{fontSize:"24pt",color:"red"}}>Because of this, voting will not work</span>
                    </div> */}
                </Col>
            </Row>
            <Row>
                <Col>
                    <WordBountyTable></WordBountyTable>
                </Col>
            </Row>
            </Container>
           
        );
    }
}

export default WordBounty;
