import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Edit from './components/Edit';
import Show from './components/Show';
import RefineSearch from './components/RefineSearch';
import { SearchContext } from './SearchContext';
import CreateWord from './components/CreateWord';
import Mission from './components/Mission';
import WhyPay from './components/WhyPay';
import HowItWorks from './components/HowItWorks';
import WBTermsOfService from './components/WBTermsOfService';
import Contact from './components/Contact';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WordDetail from './components/WordDetail';
import SearchResults from './components/SearchResults';
import TestIt from './components/TestIt';
import DefinitionCard from './components/Common/DefinitionCard';
import {getTruncatedSenderPaymail} from './components/Common/Text';
import WordBounty from './components/word-bounty/WordBounty';
import mySLictionary from './components/mySLictionary';
import {getWordCount, getTopWordSmith, getSearchCounts} from './components/Common/Words';
import WordStats from './components/WordStats';
import VoiceRecorder from './components/VoiceRecorder';
import ErrorDisplayMessage from './error-pages/error-display-message';
import ErrorInvalidTransaction from './error-pages/error-invalid-transaction';
import ErrorInvalidNavigation from './error-pages/error-invalid-navigation';
import WordBountyThankYou from './components/word-bounty/WordBountyThankYou';
import twetchPost from './components/twetch/twetchPost';
import modalTwetch from './components/modal/modalTwetch';
import logo from './assets/slictionarylogo.png';
import wordBountyIcon from './assets/wanted-word-bounty-40 percent.png';
import Dashboard from './components/Common/Dashboard';

import { getVotes, getVoteEarnings } from './mods/slictionary-vote-mod';
import firebase from './Firebase';
import { jsonSchema } from 'uuidv4';
import Login from "./components/Common/Login";

const axios = require("axios");
const config = require("./config");

try{
    let address = localStorage.getItem("walletAddress");
    let url = `${config.url.bsvService}/utxos/balance/${address}`;

    const json = axios.get(url)
        .then (resp => {
            let satoshis = Math.round(resp.data.balance.satoshis);
            //((Number(resp.data.balance.satoshis))/1000).toFixed(2).toLocaleString('en-US');
            sessionStorage.setItem("satoshiBalance", satoshis);
            sessionStorage.setItem("bsvBalance", Number(resp.data.balance.bsv));
            sessionStorage.setItem("exchangeRate", Number(resp.data.balance.exchangeRate));
        });

    let authorPaymail = localStorage["senderPaymail"];
    let db = firebase.firestore();
    var voteCount = 0;

    db.collection("votes")
    .where("author","==",authorPaymail)
    .get()
    .then((snap) => {
        voteCount = snap.size;
        sessionStorage.setItem("voteCount", voteCount);
    });

} catch (error){
    console.log(error);
}


var wordcount = getWordCount().then(function(result){ 
    sessionStorage.setItem("wordcount",result);
    let lnk = document.getElementById("wordCount");
    lnk.text = result + " " + config.app.verbiage.words;
});

// let topWordSmithHash =  getTopWordSmith().then(function(topWordSmithHash){
//     let topWordSmithPaymail = Object.keys(topWordSmithHash)[0];
//     let topWordSmithVoteCount = topWordSmithHash[topWordSmithPaymail];
//     sessionStorage.setItem("topWordSmithPaymail",topWordSmithPaymail);
//     sessionStorage.setItem("topWordSmithVoteCount", topWordSmithVoteCount); 
//     let text = `${config.app.verbiage.leaderboard.toUpperCase()}`//: ${topWordSmithPaymail} (${topWordSmithVoteCount} words)`
//     //let sp = document.getElementById("topWordSmith").text = text;
//     //sp.style.color = "#4772F6";
// });

const element = (
  
  <Router>
    <Container id="mainContainer" style={{marginLeft:"-10px",paddingRight:"0px",maxWidth:"100%",maxHeight:"100%"}}>
      <Row id="topNav" className="row justify-content-start ">

          <Col className="col-2" style={{opacity: "1.0"}}>
            <Link to="/">
                <img src={logo}/>
           </Link>
          </Col>

          <Col className="col-5" style={{textAlign:"center", marginTop:"8px", fontSize:"18pt"}}>
              <Link to="/WordStats"
                id="topWordSmith">{config.app.verbiage.leaderboard.toUpperCase()}</Link>    
          </Col>

          <Col className="col-1"  style={{textAlign:"right", marginTop:"8px"}}>
            {<Login/>}        
          </Col>

          <Col className="col-2"  style={{textAlign:"right", marginTop:"8px"}}>
          <a id="senderPaymailFooter" href="/mySLictionary">
                    {localStorage["senderPaymail"] === undefined 
                      ? ''
                      : getTruncatedSenderPaymail(localStorage.getItem("senderPaymail")).toUpperCase()}
                  </a>
          </Col>
         
          <Col id="colWordBountyImage" className="col-2" style={{display:"inline-block",textAlign:"right"}}>
          <Link to="/WordBounty" id="word-bounty" title="Word Bounty">
            <img 
                style={{width:"80px",marginTop:"10px"}}
                src={wordBountyIcon}
                />
            </Link>   

          </Col>
      </Row>

      <Row className="row ">
          <Col className="col-12">
            <div id="parentDiv" > 
            
                <SearchContext.Provider> 
                    <Route exact path='/' component={App} />
                    <Route path='/edit/:id' component={Edit} />
                    <Route path='/show/:id' component={Show} />
                    <Route path='/refineSearch/:word' component={RefineSearch} />
                    <Route path='/createword/:word/:defId/:txid' component={CreateWord} />
                    <Route path='/mission/' component={Mission} />
                    <Route path='/whypay/' component={WhyPay} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/howitworks/' component={HowItWorks} />
                    <Route path='/wbtermsofservice' component={WBTermsOfService} />
                    <Route path='/searchresults/:word/:txid' component={SearchResults} />
                    <Route path='/worddetail/:word/:defId' component={WordDetail} />
                    <Route path='/testit/:word/:txid' component={TestIt} />
                    <Route path='/DefinitionCard/:definition/:author' component={DefinitionCard} />
                    <Route path='/wordbounty/' component={WordBounty} />
                    <Route path='/mySLictionary/' component={mySLictionary} />
                    <Route path='/WordStats/' component={WordStats} />
                    <Route path='/VoiceRecorder/' component={VoiceRecorder} />
                    <Route path='/error-pages/error-display-message/' component={ErrorDisplayMessage} />
                    <Route path='/error-pages/error-invalid-transaction/' component={ErrorInvalidTransaction} />
                    <Route path='/error-pages/error-invalid-navigation/' component={ErrorInvalidNavigation} />
                    <Route path='/confirmation/wordbounty-thank-you/' component={WordBountyThankYou} />
                    <Route path='/twetchPost/' component={twetchPost} />
                    <Route path='/modalTwetch/' component={modalTwetch} />
                    <Route path='/dashboard/' component={Dashboard} />
                </SearchContext.Provider>
            </div>
          </Col>
    </Row>
    <Row id="footer">
        <Col className="col-4"></Col>
              <Col className="col-4" style={{textAlign:"center"}}> 
              <Link to="/Mission" style={{fontSize:14,color:"gray"}}>{config.app.verbiage.about}</Link>
                  &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                  <Link to="/WhyPay" style={{fontSize:14,color:"gray",whiteSpace:"nowrap"}}>{config.app.verbiage.ourPhilosophy}</Link>
                  &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                <Link id="wordCount" to="/WordStats" style={{fontSize:14,color:"#a4c2f4",whiteSpace:"nowrap"}}></Link>
                &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                
                  <Link id="contact" to="/Contact" style={{fontSize:14,color:"gray",whiteSpace:"nowrap"}} >{config.app.verbiage.contactUs}</Link>
                  &nbsp;<span style={{color:"gray"}}>|</span>&nbsp;
                  <Link to="/HowItWorks" style={{fontSize:14,color:"gray",whiteSpace:"nowrap"}}>{config.app.verbiage.howItWorks}</Link>
              </Col>
              <Col className="col-4"></Col>
      </Row>
    </Container>
  </Router>
);

ReactDOM.render(element,document.getElementById('root'));

serviceWorker.unregister();