//import firebase from '../../Firebase';
import {gimmeDoc} from './Document';
import { getVoteSum } from './Vote';

const getArrays = async (word, defId, type,opts) => {
    let docRef = null;

    if(opts){
        docRef = opts;
    } else {
        docRef = await gimmeDoc(word);
    }

    let json = docRef.data();
    for (var key in json.definitions) {
        var item = json.definitions[key];
       
            switch(type){
                case "def" :
                    return item.defVersions;
                    break;
                case "img" :
                    return item.images;
                    break;
                case "pro" :
                    return item.pronunciations;
                    break;
                case "part" :
                    return item.partOfSpeech;
                    break;
                }
    }
}

const getDefinitions = async (word, defId, docRef) => {
    if (!docRef){
        return getArrays(word,defId,"def");
    } else {
        return getArrays(word,defId,"def",docRef);
    }
}

const getImages = async (word, defId, docRef) => {
    if (docRef){
        return getArrays(word, defId,"img",docRef);
    } else {
        return getArrays(word, defId,"img");
    }

}

const getPronunciations = async (word, defId, docRef) => {

    if (docRef){
        return getArrays(word, defId,"pro",docRef);
    } else {
        return getArrays(word, defId,"pro");
    }
}

const getPartsOfSpeech = async (word, defId, docRef) => {

    if (docRef){
        return getArrays(word, defId,"part",docRef);
    } else {
        return getArrays(word, defId,"part");
    }
}



export {getDefinitions, getImages, getPronunciations, getPartsOfSpeech};