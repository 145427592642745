
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import axios from 'axios';
import React, { useState, useCallback } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import config from '../../config';
//import Button from '@inovua/reactdatagrid-community/packages/Button'


const SMITHED_WORDS_URL = `${config.url.wordService}/revenue/words`;
const WORD_BOUNTY_URL = `${config.url.wordBountyService}/submitted/10`;

const gridStyle = { marginTop: 10, minHeight: 550 }

const smithedWordsColumns = [
    { name: 'word', header: 'Word', minWidth: 50, defaultFlex: 2 },
    { name: 'createDateTime', header: 'Create Datetime', minWidth: 50, defaultFlex: 2 },
    { name: 'userId', header: 'Author', minWidth: 50, defaultFlex: 2 },
    { name: 'type', header: 'Type', minWidth: 50, defaultFlex: 2 }
];

const wordBountyColumns = [
    { name: 'word', header: 'Word', minWidth: 50, defaultFlex: 2 },
    { name: 'bounty', header: 'Bounty', minWidth: 50, defaultFlex: 2 },
    { name: 'createDatetime', header: 'Create Datetime', minWidth: 50, defaultFlex: 2 },
    { name: 'status', header: 'Status', minWidth: 50, defaultFlex: 2 },
    { name: 'submitter', header: 'Sponsor', minWidth: 50, defaultFlex: 2 },
    { name: 'voteStart', header: 'Vote Start', minWidth: 50, defaultFlex: 2 },
    { name: 'winner', header: 'Winner', minWidth: 50, defaultFlex: 2 }
];


const loadSmitedWordsData = () => {
    return fetch(
      SMITHED_WORDS_URL
    ).then(response => response.json());
  };

const loadWordBountyData = () => {
    return fetch(
        WORD_BOUNTY_URL
    ).then(response => response.json());
};


const App = () => {
    const smithedWordsDataSource = useCallback(loadSmitedWordsData, []);
    const wordBountyDataSource = useCallback(loadWordBountyData,[]);
    const [columns] = useState(smithedWordsColumns);
    const [wbColumns] = useState(wordBountyColumns);
    document.body.style.backgroundImage = "url('')";
  return (
    <div>
        <div style={{fontSize:"18pt",fontWeight:"bold"}}>Last 50 Smithed Words</div>
        <ReactDataGrid
            style={gridStyle}
            idProperty="id"
            columns={smithedWordsColumns}
            dataSource={smithedWordsDataSource}
            theme={"default-dark"}
        />
        <div>&nbsp;</div>
        <div style={{fontSize:"18pt",fontWeight:"bold"}}>Recent Word Bounties</div>
        <ReactDataGrid
            style={gridStyle}
            idProperty="id"
            columns={wbColumns}
            dataSource={wordBountyDataSource}
            theme={"default-dark"}
        />

    </div>
  )
}

export default () => <App />



