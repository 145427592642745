import React from 'react';
import BaseComponent from '../components/Common/BaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css';

class ErrorDisplayMessage extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentWillMount(){
    document.body.style.backgroundImage = "url('')";
  }
  
  render() {

    return (

      <div id="parentContainer" className="content" style={{color:"yellow"}}>
          <h1>WHoops! SOmething is rotten in Denmark!</h1>
          <img alt="" src="https://firebasestorage.googleapis.com/v0/b/slictionary-fc2a0.appspot.com/o/shakespeare-in-pain.jpg?alt=media&token=1feb8bb3-48e2-4f53-a5d8-f36e1aa5f32d"/>
          <h2 style={{color:"yellow"}}>We'll get our developers on it</h2>
      </div>
    );
  }
}

export default ErrorDisplayMessage;