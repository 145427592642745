import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import TabControl from './TabControl';

class WordDetail extends Component {

  constructor(props) {
    super(props);

  }

 render() {

    return (

      <div className="content" style={{color:"white"}}>
        <Container>
        {<TabControl />} 
       
        {/* <i class="far fa-lightbulb fa-3x" title="Do you like my fa-coq icon?"></i>&nbsp;&nbsp;<i class="fa fas fa-lightbulb fa-3x"></i>
            &nbsp;&nbsp;
            <i class="fas fa-book-open fa-3x"></i>&nbsp;&nbsp;<i class="fa fas fa-book-open fa-3x"></i> */}
            
            
        </Container>
      </div>
    );
  }
}

export default WordDetail;