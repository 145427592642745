const firstBy=(function(){function e(f){f.thenBy=t;return f}function t(y,x){x=this;return e(function(a,b){return x(a,b)||y(a,b)})}return e})();
const { uuid } = require('uuidv4');

function sortByProperty(property){  
    return function(a,b){  
       if(a[property] < b[property])  
          return 1;  
       else if(a[property] > b[property])  
          return -1;  
   
       return 0;  
    }  
 }

function sortByMultiple(arr){
 
    arr.sort(
        // firstBy(function (v1, v2) { return v2.bounty - v1.bounty; })
        // .thenBy(function (v1, v2) { return v1.word > v2.word; })
        function sortF(ob1,ob2){
        if (ob1.bounty < ob2.bounty) {
            return 1;
        } else if (ob1.bounty > ob2.bounty) { 
            return -1;
        }
    
        // Else go to the 2nd item
        if (ob1.word < ob2.word) { 
            return -1;
        } else if (ob1.word > ob2.word) {
            return 1
        } else { // nothing to split them
            return 0;
        }
    }
    );
    return arr;
}

function getUUID(){
    let guid = uuid();
    return guid.replace(/-/g,"");
}
 
 export {sortByMultiple, getUUID};